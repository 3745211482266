import { useState } from 'react';
import {
  PROPOSAL_STATES,
  PROPOSAL_TYPES,
} from '../../../../types/proposals.types';
import { Toast } from '../../../../components';
import DisapprovedProposal from './actions/DisapprovedProposal';
import ProposalStorno from './actions/ProposalStorno';
import ProposalCompletion from './actions/ProposalCompletion';
import { ToastActionType } from '../../../../types/drugProposals.types';

interface Props {
  insureeIdTxs: number;
  proposalCompletionText: string;
  proposalId: number;
  proposalNumber: string;
  state: string;
  type: PROPOSAL_TYPES;
}

const HeaderLNActions = ({
  insureeIdTxs,
  proposalCompletionText,
  proposalId,
  proposalNumber,
  state,
  type,
}: Props) => {
  const [toastState, setToastState] = useState<{
    isVisible: boolean;
    onHide: () => void;
    text: string;
    type: 'success' | 'error' | null;
  }>({
    isVisible: false,
    onHide: () => {},
    text: '',
    type: 'success',
  });

  return (
    <>
      {state === PROPOSAL_STATES.InProgress && (
        <ProposalStorno
          proposalId={proposalId}
          toastAction={({ isVisible, onHide, result, text }: ToastActionType) =>
            setToastState({
              isVisible,
              onHide: () => {
                if (onHide) onHide();
              },
              text,
              type: result,
            })
          }
          type={type}
        />
      )}
      {state === PROPOSAL_STATES.Notapproved && (
        <DisapprovedProposal
          proposalId={proposalId}
          proposalNumber={proposalNumber}
        />
      )}
      {state === PROPOSAL_STATES.ToSupplement && (
        <ProposalCompletion
          insureeIdTxs={insureeIdTxs}
          proposalCompletionText={proposalCompletionText}
          proposalId={proposalId}
          proposalNumber={proposalNumber}
          proposalType={type}
          state={state}
          toastAction={({ isVisible, onHide, result, text }: ToastActionType) =>
            setToastState({
              isVisible,
              onHide: () => {
                if (onHide) onHide();
              },
              text,
              type: result,
            })
          }
        />
      )}
      {toastState.isVisible && (
        <Toast
          hideAfter={5000}
          iconType={toastState.type || 'success'}
          onHide={() => {
            setToastState({ ...toastState, isVisible: false });
            toastState.onHide();
          }}
          show={toastState.isVisible}
          text={toastState.text}
        />
      )}
    </>
  );
};

export default HeaderLNActions;
