import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetFormDataResponse,
  GetListOfAvailableDoctorsResponse,
  ProposalCheck,
  ProposalDiagnose,
  StepStatus,
} from '../types/proposals.types';
import {
  CheckProposalDataResponse,
  GetApprovedProposalsResponse,
  GetProposalDetailDocumentsSPAResponse,
  GetProposalDetailResponse,
  ProposalListFilter,
  ResetAfterKN,
  SPAProposalsState,
  SaveProposalResponse,
  StoreStep3Payload,
} from '../types/spaProposals.types';
import {
  checkProposalData,
  getApprovedProposals,
  getFormData,
  getListOfAvailableDoctors,
  getProposalDetail,
  getProposalDetailDocuments,
  saveProposal,
} from '../api/spaProposals';
import strings from '../constants/strings';
import { validationMsg } from '../utils/form.utils';
import { VALIDATION } from '../types/validation.types';
import {
  getStep3Values,
  getTxsControlNtfs,
  isLoadedTXSProposalControls,
  spaBaseCheckTypes,
} from '../utils/spaProposals.utils';
import { formatNameStr } from '../utils/strings.utils';
import { spaProposalsInitialState } from '../constants/proposals';

// for mock, import spaProposalMockedState
const initialState: SPAProposalsState = spaProposalsInitialState;

const spaProposalsSlice = createSlice({
  name: 'spaProposalsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getListOfAvailableDoctors.pending.toString(), (state) => ({
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            doctors: {
              ...initialState.new.data.doctors,
              isLoading: true,
            },
          },
        },
      }))
      .addCase(
        getListOfAvailableDoctors.fulfilled.toString(),
        (state, action: PayloadAction<GetListOfAvailableDoctorsResponse>) => ({
          ...state,
          new: {
            ...state.new,
            data: {
              ...state.new.data,
              doctors: {
                ...initialState.new.data.doctors,
                data: action.payload?.zoznamDostupnychLekarov,
              },
            },
          },
        }),
      )
      .addCase(getListOfAvailableDoctors.rejected.toString(), (state) => ({
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            doctors: {
              ...initialState.new.data.doctors,
              error: strings.defaultError,
            },
          },
        },
      }))
      .addCase(checkProposalData.pending.toString(), (state, action: any) => {
        const actualState = state.new.stepper.step3;
        state.new.data.controls.payload = {
          ...state.new.data.controls.payload,
          ...action?.meta?.arg,
          isDg: {
            ...action?.meta?.arg?.isDg,
            kodDiagnoza: state.new.stepper.step3.diagnose?.kod || undefined,
            kodIndikacnaSkupina:
              state.new.stepper.step3.indicatorGroup?.kod || undefined,
            kodOdbNavrhujucaNZ:
              state.new.stepper.step1.ambulance.expertise || undefined,
            kodOdbNavrhujuciLek:
              state.new.stepper.step1.doctor.expertise || undefined,
          },
        };
        if (
          state.new.stepper.step3.diagnose?.kod &&
          state.new.stepper.step3.indicatorGroup?.kod
        ) {
          // reset TXS controls
          state.new.data.controls.data = state.new.data.controls.data.filter(
            (d) => spaBaseCheckTypes.some((c) => c === d.checkType),
          );
        }
        // reset all step3 fields after eventDate
        state.new.stepper.step3 = {
          ...initialState.new.stepper.step3,
          diagnose: actualState.diagnose,
          eventDate: actualState.eventDate,
          indicatorGroup: actualState.indicatorGroup,
          spaConfirmation: actualState.spaConfirmation,
        };
        state.new.data.controls.isLoadingControl =
          action?.meta?.arg?.kontrolaTypy || null;
        state.new.data.controls.error = '';
      })
      .addCase(
        checkProposalData.fulfilled.toString(),
        (state, action: PayloadAction<CheckProposalDataResponse>) => {
          const { payload } = action;
          const obj =
            payload?.udaje?.filter(
              (d) => d.kontrolaTyp === 'PoistnyVztah' && d.doplnujuceInfo,
            )?.[0]?.doplnujuceInfo || null;
          let controls = state.new.data.controls.data;
          const removeIndexes: number[] = [];
          payload?.udaje
            ?.map((u) => u.kontrolaTyp)
            .forEach((t) => {
              const index = controls.findIndex((o) => o.checkType === t);
              if (index !== -1) removeIndexes.push(index);
            });
          controls = controls
            .filter((c, key) => !removeIndexes.includes(key))
            .map((c) => c);
          state.new.data.controls = {
            ...state.new.data.controls,
            error: '',
            errorInsuree: '',
            isLoadingControl: null,
            data: [
              ...controls,
              ...(payload?.udaje?.map<ProposalCheck>((u) => ({
                checkType: u.kontrolaTyp,
                description: u.popisChyby,
                errorCode: u.kodChyby,
                errorWeight: u.kodVahaChyby,
                result: u.vysledokKontroly,
              })) || []),
            ],
          };
          if (
            isLoadedTXSProposalControls(
              state.new.data.controls.data.map((d) => d.checkType),
            )
          ) {
            state.new.data.controls.isLoadedTXSControls = true;
            if (
              getTxsControlNtfs(
                state.new.data.controls.data,
                state.new.data.formData.data?.udajParUdalIndSkup?.perioda || 0,
              )
            )
              state.new.stepper.step3.showCancelBtnAfter = 'spaTXSControls';
          }
          if (obj) {
            state.new.stepper.step2.poiData = {
              address: obj.hodnota3,
              dateOfBirth: obj.hodnota6,
              firstName: obj.hodnota2,
              lastName: obj.hodnota1,
              mobile: obj.hodnota4,
              sex: obj.hodnota5,
            };
          }
        },
      )
      .addCase(checkProposalData.rejected.toString(), (state) => {
        state.new.data.controls.isLoadingControl = null;
        state.new.data.controls.error = strings.defaultError;
        state.new.data.controls.errorInsuree = validationMsg(
          VALIDATION.NotDZPInsuree,
        );
      })
      .addCase(getFormData.pending.toString(), (state, action: any) => {
        const dg = action?.meta?.arg?.kodDiagnoza;
        const is = action?.meta?.arg?.kodIndikacnaSkupina;
        if (dg && is)
          state.new.data.controls.payload = {
            ...state.new.data.controls.payload,
            isDg: {
              ...state.new.data.controls.payload.isDg,
              kodDiagnoza: dg || undefined,
              kodIndikacnaSkupina: is || undefined,
            },
          };
        state.new.data.formData.isLoading = true;
      })
      .addCase(
        getFormData.fulfilled.toString(),
        (state, action: PayloadAction<GetFormDataResponse>) => {
          state.new.data.formData = {
            ...state.new.data.formData,
            data: action.payload,
            isLoading: false,
          };
        },
      )
      .addCase(getFormData.rejected.toString(), (state) => {
        state.new.data.formData = {
          ...state.new.data.formData,
          error: strings.defaultError,
          isLoading: false,
        };
      })
      .addCase(getApprovedProposals.pending.toString(), (state) => {
        state.list.isLoading = true;
        state.list.submittedProposals = initialState.list.submittedProposals;
      })
      .addCase(
        getApprovedProposals.fulfilled.toString(),
        (state, action: PayloadAction<GetApprovedProposalsResponse>) => {
          state.list.approvedProposals.data = action.payload.navrhy;
          state.list.isLoading = false;
        },
      )
      .addCase(getApprovedProposals.rejected.toString(), (state) => ({
        ...state,
        list: {
          ...initialState.list,
          approvedProposals: {
            ...initialState.list.approvedProposals,
            error: strings.defaultError,
          },
        },
      }))
      .addCase(getProposalDetail.pending.toString(), (state) => {
        state.detail = {
          ...initialState.detail,
          isLoading: true,
        };
      })
      .addCase(
        getProposalDetail.fulfilled.toString(),
        (state, action: PayloadAction<GetProposalDetailResponse>) => {
          state.detail = {
            ...state.detail,
            data: action.payload,
            isLoading: false,
          };
        },
      )
      .addCase(getProposalDetail.rejected.toString(), (state, err: any) => {
        state.detail = {
          ...initialState.detail,
          error: err?.response?.data?.text || strings.defaultError,
        };
      })
      .addCase(getProposalDetailDocuments.pending.toString(), (state) => {
        state.detail.documents = {
          ...initialState.detail.documents,
          isLoading: true,
        };
      })
      .addCase(
        getProposalDetailDocuments.fulfilled.toString(),
        (
          state,
          action: PayloadAction<GetProposalDetailDocumentsSPAResponse>,
        ) => {
          state.detail.documents = {
            ...initialState.detail.documents,
            data: action.payload,
            isLoading: false,
          };
        },
      )
      .addCase(
        getProposalDetailDocuments.rejected.toString(),
        (state, err: any) => {
          state.detail.documents = {
            ...initialState.detail.documents,
            error: err?.response?.data?.text || strings.defaultError,
          };
        },
      )
      .addCase(saveProposal.pending.toString(), (state) => {
        state.new.save.isLoading = true;
      })
      .addCase(
        saveProposal.fulfilled.toString(),
        (state, action: PayloadAction<SaveProposalResponse>) => {
          state.new.save = {
            data: action.payload,
            error: null,
            isLoading: false,
          };
        },
      )
      .addCase(saveProposal.rejected.toString(), (state) => {
        state.new.save = {
          data: null,
          error: 'Prepáčte, návrh sa nepodarilo odoslať',
          isLoading: false,
        };
      });
  },
  reducers: {
    storeStep1(
      state,
      action: PayloadAction<{ ambulanceId: string; doctorId: string }>,
    ) {
      const doctor = state.new.data.doctors.data?.filter(
        (d) => d.idLekarZdravPrac === Number(action.payload.doctorId),
      )?.[0];
      const ambulance = doctor?.ambulancie?.filter(
        (a) => a.idNZ === Number(action.payload.ambulanceId),
      )?.[0];
      const { ambulanceId, doctorId } = action.payload;
      return {
        ...state,
        new: {
          ...state.new,
          isReseted: false,
          stepper: {
            ...(Number(ambulanceId) !== state.new.stepper.step1.ambulance?.id ||
            Number(doctorId) !== state.new.stepper.step1.doctor?.id
              ? initialState.new.stepper
              : state.new.stepper),
            step1: {
              ...state.new.stepper.step1,
              ambulance: {
                id: Number(ambulanceId),
                code: ambulance?.kodNZ || '',
                expertise: ambulance?.odbornostNZ || '',
                name: ambulance?.nazovNZ || '',
              },
              doctor: {
                id: Number(doctorId),
                code: doctor?.kodLekara || '',
                email: doctor?.email || '',
                expertise: doctor?.odbornostLekara || '',
                name: formatNameStr(doctor?.menoLekara || ''),
              },
            },
          },
        },
      };
    },
    storeStep2(
      state,
      action: PayloadAction<{
        insureeId: string;
      }>,
    ) {
      return {
        ...state,
        new: {
          ...state.new,
          resetAfterFilled: 'RC',
          stepper: {
            ...state.new.stepper,
            step1: state.new.stepper.step1,
            step2: {
              ...state.new.stepper.step2,
              insureeId: action.payload.insureeId,
            },
            step3: initialState.new.stepper.step3,
            step4: initialState.new.stepper.step4,
            step5: initialState.new.stepper.step5,
          },
        },
      };
    },
    storeStep3(state, action: PayloadAction<StoreStep3Payload>) {
      const { date, diagnose, indicatorGroup, reasonDescription } =
        action.payload;

      const {
        actualState,
        cancelBtnValue,
        currentDiagnose,
        currentIndicatorGroup,
        currentSpaConfirmation,
        currentSpaContraindications,
        currentSpaExaminations,
        currentSpaSVLZ,
        resetAfterFilled,
      } = getStep3Values(
        action.payload,
        initialState.new.stepper,
        state.new.stepper,
      );

      return {
        ...state,
        new: {
          ...state.new,
          resetAfterFilled,
          stepper: {
            ...state.new.stepper,
            step3: {
              ...actualState,
              diagnose: currentDiagnose,
              eventDate: date || actualState.eventDate,
              indicatorGroup: currentIndicatorGroup,
              reasonDescription:
                reasonDescription || actualState.reasonDescription,
              showCancelBtnAfter: cancelBtnValue,
              spaConfirmation: currentSpaConfirmation,
              spaContraindications: currentSpaContraindications,
              spaExaminations: currentSpaExaminations,
              spaSVLZ: currentSpaSVLZ,
            },
            step4:
              diagnose || indicatorGroup
                ? initialState.new.stepper.step4
                : state.new.stepper.step4,
            step5:
              diagnose || indicatorGroup
                ? initialState.new.stepper.step5
                : state.new.stepper.step5,
          },
        },
      };
    },
    storeStep4(
      state,
      action: PayloadAction<{
        description?: string;
        diagnoses?: ProposalDiagnose[];
      }>,
    ) {
      state.new.stepper.step4 = {
        ...state.new.stepper.step4,
        description:
          action.payload.description || state.new.stepper.step4.description,
        diagnoses:
          action.payload.diagnoses || state.new.stepper.step4.diagnoses,
      };
    },
    storeStep5(
      state,
      action: PayloadAction<{
        escort?: 'Y' | 'N' | string;
        helpingEscort?: string;
        mobility?: 'C' | 'B' | 'V' | string;
        spaType?: 'U' | 'A' | string;
      }>,
    ) {
      const { escort, helpingEscort, mobility, spaType } = action.payload;
      const actualState = state.new.stepper.step5;
      state.new.stepper.step5 = {
        ...actualState,
        escort: escort || actualState.escort,
        helpingEscort: helpingEscort || actualState.helpingEscort,
        mobility: mobility || actualState.mobility,
        spaType: spaType || actualState.spaType,
      };
    },
    changeActiveStep(state, action: PayloadAction<{ step: number }>) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            activeStep: action.payload.step,
          },
        },
      };
    },
    changeStepStatus(
      state,
      action: PayloadAction<{ status: StepStatus; step: number }>,
    ) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            [`step${action.payload.step}`]: {
              ...state.new.stepper[`step${action.payload.step}`],
              stepStatus: action.payload.status,
            },
          },
        },
      };
    },
    reset() {
      return {
        ...initialState,
        new: {
          ...initialState.new,
          isReseted: true,
        },
      };
    },
    resetStep(state, action: PayloadAction<{ step: number }>) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            [`step${action.payload.step}`]:
              initialState.new.stepper[`step${action.payload.step}`],
          },
        },
      };
    },
    resetApprovedProposals(state) {
      return {
        ...state,
        list: {
          ...state.list,
          approvedProposals: initialState.list.approvedProposals,
        },
      };
    },
    resetControls(state) {
      return {
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            controls: {
              ...state.new.data.controls,
              data: state.new.data.controls.data.filter(
                (d) => d.checkType === 'PoistnyVztah',
              ),
              error: '',
            },
          },
        },
      };
    },
    resetNewProposalSpaForm(state) {
      return {
        ...state,
        new: initialState.new,
      };
    },
    resetFormData(state) {
      state.new.data.formData = initialState.new.data.formData;
    },
    storeQueryParams(state, action: PayloadAction<{ queries: any }>) {
      return {
        ...state,
        list: {
          ...state.list,
          queryParams: action.payload.queries,
        },
      };
    },
    setListFilter(
      state,
      action: PayloadAction<{ values: ProposalListFilter }>,
    ) {
      return {
        ...state,
        list: {
          ...state.list,
          storedFilter: action.payload.values,
        },
      };
    },
    setResetAfterFilled(
      state,
      action: PayloadAction<{ value?: ResetAfterKN }>,
    ) {
      return {
        ...state,
        new: {
          ...state.new,
          resetAfterFilled: action.payload.value,
        },
      };
    },
    setTableOrder(
      state,
      action: PayloadAction<{ key: string; order: 'asc' | 'desc' }>,
    ) {
      return {
        ...state,
        list: {
          ...state.list,
          tableOrder: {
            key: action.payload.key,
            order: action.payload.order,
          },
        },
      };
    },
    resetListFilter(state) {
      return {
        ...state,
        list: {
          ...state.list,
          storedFilter: null,
        },
      };
    },
  },
});

export const {
  changeActiveStep,
  changeStepStatus,
  reset,
  resetApprovedProposals,
  resetControls,
  resetFormData,
  resetListFilter,
  resetNewProposalSpaForm,
  resetStep,
  setListFilter,
  setResetAfterFilled,
  setTableOrder,
  storeQueryParams,
  storeStep1,
  storeStep2,
  storeStep3,
  storeStep4,
  storeStep5,
} = spaProposalsSlice.actions;

export default spaProposalsSlice.reducer;
