import strings from '../../../../../constants/strings';
import { DetailDoctor } from '../../../../../types/drugProposals.types';
import {
  formatNameStr,
  hotjarMasking,
} from '../../../../../utils/strings.utils';
import Section from './Section';

const texts = strings.proposals.spa.detail;

interface Props {
  proposalDoctor: DetailDoctor;
}

const Doctor = ({ proposalDoctor }: Props) => {
  const { ambulance, doctor, email, hospital } = proposalDoctor;
  return (
    <Section
      rows={[
        {
          label: texts.labels.doctor,
          value: hotjarMasking(formatNameStr(doctor)),
        },
        {
          label: texts.labels.hospital,
          value: hospital ? formatNameStr(hospital) : strings.undefined,
        },
        {
          label: texts.labels.ambulance,
          value: ambulance ? formatNameStr(ambulance) : strings.undefined,
        },
        {
          label: texts.labels.email,
          value: email ? hotjarMasking(email) : strings.undefined,
        },
      ]}
      title={texts.subtitles.proposalDoctor}
    />
  );
};

export default Doctor;
