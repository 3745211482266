import strings from '../../../../../constants/strings';
import { GetProposalDetailResponse } from '../../../../../types/spaProposals.types';
import Section from './Section';

const texts = strings.proposals.spa.detail;

interface Props {
  doplnujuceInformacie: GetProposalDetailResponse['doplnujuceInformacie'];
}

const AdditionalInfo = ({ doplnujuceInformacie }: Props) => (
  <Section
    className="text-space-top"
    rows={[
      {
        label: texts.labels.spaType,
        value: doplnujuceInformacie.kupelnaStarostlivost || strings.undefined,
      },
      {
        label: texts.labels.escort,
        value: doplnujuceInformacie.sprievod ? 'Áno' : 'Nie',
      },
      ...(doplnujuceInformacie.sprievod
        ? [
            {
              label: texts.labels.escortType,
              value: doplnujuceInformacie.dovodSprievodu
                ? doplnujuceInformacie.dovodSprievodu.split(';').join(', ')
                : strings.undefined,
            },
          ]
        : []),
      {
        label: texts.labels.mobility,
        value: doplnujuceInformacie.pohyblivostPacienta || strings.undefined,
      },
    ]}
    title={texts.subtitles.additionalInfo}
  />
);

export default AdditionalInfo;
