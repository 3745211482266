import { breakpoints, color, fontWeight, getRem } from '@dovera/design-system';
import { jss } from 'react-jss';

import { HEADER_HEIGHT } from './constants';
import { ns } from './utils/object.utils';
import { isIE } from './utils/app.utils';

const baseUri = ns(() => window.env.config.baseURI, '');

jss
  .createStyleSheet({
    '@global html, body': {
      fontFamily: 'DoveraSans',
      overflowX: 'hidden',
    },
    '@global header.header .container': {
      maxWidth: '100%',
    },
    '@global #root': {
      minHeight: '100vh',
    },
    '@global #content': {
      paddingTop: `${HEADER_HEIGHT}px`,
      minHeight: `calc(100vh - 67px)`,
      background: color('grey', 50),
    },
    '@global .bar__item svg.icon': {
      verticalAlign: 'middle',
      marginTop: -5,
    },
    '@global .notification svg.icon': {
      marginTop: 'auto',
      vertialAlign: 'auto',
    },
    '@global svg.icon--pos--category': {
      verticalAlign: 'middle',
      marginTop: -2,
    },
    '@global p svg.icon': {
      verticalAlign: 'middle',
      marginTop: -3,
    },
    '@global .withIcon svg.icon': {
      verticalAlign: 'middle',
      marginTop: -4,
      marginLeft: 5,
    },
    '@global *:focus': {
      outline: 'none',
    },
    '@global #parameter-formula-superdetail .modal__overlay': {
      backgroundColor: color('white'),
    },
    '@global .link': {
      wordWrap: 'inherit',
      wordBreak: 'inherit',
    },
    '@global .bar__item': {
      wordWrap: 'normal',
      wordBreak: 'normal',
      maxWidth: '100%',
    },
    '@global div > span': {
      wordBreak: 'break-word',
    },
    '@global .dlek--break-content': {
      wordBreak: 'break-word',
    },
    '@global span.inlineLastP p:last-child': {
      display: 'inline',
    },
    '@global *[role=button]:focus': {
      outline: 'none',
    },
    '@global .page-section': {
      width: isIE ? '100%' : 'inherit',
    },
    '@global .unselectable': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      userSelect: 'none' /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */,
    },
    '@global .content-center-vert svg': {
      verticalAlign: 'middle',
      marginTop: -4,
      marginRight: 8,
    },
    '@global .my-masonry-grid': {
      display: 'flex',
      marginLeft: -30,
      width: 'auto',
    },
    '@global .my-masonry-grid_column': {
      paddingLeft: 30,
      backgroundClip: 'padding-box',
    },
    '@global .table td': {
      borderLeft: isIE ? 'none' : 'inherit',
      borderBottom: isIE ? 'none' : 'inherit',
      border: isIE ? '1px solid #e1e2e3' : '',
    },
    '@global .table td:last-child': {
      borderRight: isIE ? 'none' : 'inherit',
    },
    '@global .table td:not(:last-child):after': {
      borderRight: isIE ? 'none' : '1px solid #e1e2e3',
    },
    '@global .d-block': {
      display: 'block !important',
    },
    '@global .d-inline-flex': {
      display: 'inline-flex !important',
    },
    '@global .d-inline': {
      display: 'inline !important',
    },
    '@global .d-inline > div': {
      display: 'inline',
    },
    '@global .d-inline > p': {
      marginBottom: 8,
    },
    '@global .inline-btn': {
      minHeight: 0,
      margin: `-1px 0 0 0`,
      padding: 0,
      alignItems: 'center',
      textAlign: 'left',
    },
    '@global .inline-btn svg': {
      marginLeft: getRem(8),
    },
    '@global .inline-btn div:not(.loader)': {
      marginLeft: getRem(8),
      marginRight: `0 !important`,
    },
    '@global .inline-btn div.loader': {
      marginRight: getRem(12),
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: 6,
      },
      '*::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '*::-webkit-scrollbar-thumb': {
        background: '#DADADA',
        borderRadius: 8,
      },
      '*::-webkit-scrollbar:horizontal': {
        height: 5,
      },
      '*::-webkit-scrollbar-thumb:horizontal': {
        borderRadius: 10,
      },
      small: {
        color: color('grey', 500),
        fontSize: getRem(14),
      },
      '.modal__header .modal__title': {
        marginBottom: 0,
      },
      '.footer-app .container': {
        padding: '0 2rem',
      },
      '.table-caption--hide caption': {
        margin: 0,
      },
      '.input-addons': {
        borderRadius: 8,
      },
      '.input-addons__item': {
        border: 'none',
        borderLeft: 'none',
        minHeight: 48,
        [`@media screen and (max-width: ${breakpoints.m}px)`]: {
          minHeight: 'auto',
        },
      },
      '.content-center-vert': {
        display: 'flex',
        alignItems: 'center',
        [`@media screen and (max-width: ${breakpoints.s}px)`]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      '.content-center-horiz': {
        display: 'flex',
        justifyContent: 'center',
      },
      '.content-center-icons': {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:first-child)': {
          marginLeft: getRem(16),
        },
      },
      '#select-expertise .input-addons__item': {
        minHeight: 'auto !important',
      },
      '.choices__list--dropdown': {
        maxHeight: 200,
        overflow: 'hidden',
      },
      '.choices__list--dropdown .choices__list': {
        maxHeight: '100%',
      },
      '.navigation__list button': {
        padding: 0,
      },
      '.navigation__list button > span': {
        width: '100%',
        textAlign: 'left',
      },
      '.tooltip[aria-hidden=true]': {
        display: 'none',
      },
      '.text-color-error-base': {
        color: `${color('error')} !important`,
      },
      '.text-color-grey-light': {
        color: `${color('grey', 500)} !important`,
      },
      '.card .link': {
        textDecoration: 'underline',
      },
      '.tooltip-container': {
        maxWidth: '55ch !important',
        padding: '1rem 1rem !important',
        backgroundColor: '#133463 !important',
        borderRadius: '8px !important',
        fontSize: '14px !important',
        lineHeight: '1.714 !important',
        color: '#fff',
        display: 'block',
        border: '0 !important',
        transform: 'translate3d(0,.5rem,0)',
        marginBottom: '12px !important',
      },
      '.text-space-quarter-left': {
        marginLeft: '0.25rem !important',
      },
      '.text-space-quarter-right': {
        marginRight: '0.25rem !important',
      },
      '.text-space-half-left': {
        marginLeft: '0.5rem !important',
      },
      '.text-space-half-right': {
        marginRight: '0.5rem !important',
      },
      '.text-space-two-thirds': {
        marginRight: '0.7rem !important',
      },
      'button.tab': {
        display: 'flex',
        alignItems: 'center',
      },
      'button.tab svg': {
        marginTop: -3,
        marginRight: 8,
      },
      '.form-control.is-error .input-addons__item': {
        display: 'none',
      },
      '.form-control.is-error .input-addons--inside input': {
        borderRadius: 8,
      },
      '.input-addons.input-addons--inside': {
        border: '2px solid #c1c6c6',
      },
      '.is-error .input-addons.input-addons--inside': {
        border: `2px solid ${color('error')}`,
      },
      '.fs-14': {
        fontSize: '14px !important',
      },
      '.fs-16': {
        fontSize: '16px !important',
      },
      '.fs-18': {
        fontSize: '18px !important',
      },
      '.pad-left': {
        paddingLeft: '1rem !important',
      },
      '.pad-bottom': {
        paddingBottom: getRem(24),
      },
      '.pad-bottom-small': {
        paddingBottom: getRem(8),
      },
      '.align-center': {
        alignItems: 'center !important',
      },
      '.tooltip__content a': {
        color: 'white !important',
        textDecoration: 'underline',
      },
      '.form-control--textarea label': {
        width: '100%',
      },
      '.tooltip__content': {
        maxWidth: '60ch',
      },
      '.table-app__cell': {
        '&:first-child': {
          borderTopLeftRadius: getRem(5),
          borderBottomLeftRadius: getRem(5),
        },
        '&:last-child': {
          borderTopRightRadius: getRem(5),
          borderBottomRightRadius: getRem(5),
        },
      },
      '.icon-with-text': {
        display: 'flex',
        alignItems: 'center',
      },
      '.icon-with-text svg': {
        marginRight: getRem(8),
      },
      '.underline': {
        textDecoration: 'underline',
      },
      '.fit-content': {
        width: 'fit-content',
      },
      '.text-thin': {
        fontWeight: 'normal',
      },
      '.notification-container .notification': {
        marginBottom: 0,
      },
      '.hide-xxl-s': {
        [`@media not all and (min-width: ${breakpoints.xl}px) and (max-width: 1430px)`]:
          {
            display: 'none',
          },
      },
      '.table-responsive-sh': {
        fontSize: 16,
        '& p': {
          fontSize: 16,
        },
        '& td': {
          padding: '.5rem 1rem',
        },
        '& table table': {
          border: 'none',
          marginBottom: 0,
          '& a': {
            fontSize: 16,
            fontWeight: 'bold',
          },
          '& table td': {
            padding: 0,
            border: 'none',
            '&:after': {
              display: 'none',
            },
          },
        },
        '& .table-responsive-sh': {
          marginBottom: 0,
        },
      },
      '.tab--link.is-active': {
        color: color('success', 600),
      },
      '.select--inline .choices__inner': {
        color: color('success', 600),
      },
      '.w-100': {
        width: '100%',
      },
    },
  })
  .attach();

jss
  .createStyleSheet({
    // @ts-ignore
    '@font-face': [
      {
        fontFamily: 'DoveraSans',
        src: `url("${baseUri}/fonts/dovera-sans/DoveraSans-Regular.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Regular.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Regular.ttf) format('truetype');`,
      },
      {
        fontFamily: 'DoveraSans',
        src: `url("${baseUri}/fonts/dovera-sans/DoveraSans-Medium.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Medium.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Medium.ttf) format('truetype');`,
        fontWeight: fontWeight.semiBold,
      },
      {
        fontFamily: 'DoveraSans',
        src: `url("${baseUri}/fonts/dovera-sans/DoveraSans-Bold.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Bold.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Bold.ttf) format('truetype')`,
        fontWeight: fontWeight.bold,
      },
      {
        fontFamily: 'DoveraSans',
        src: `url("${baseUri}/fonts/dovera-sans/DoveraSans-Light.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Light.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Light.ttf) format('truetype');`,
        fontWeight: 100,
      },
      {
        fontFamily: 'Amalia',
        src: `url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002686-37380661e5c3045a9774c347e774736e.woff2") format("woff2"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002686-37380661e5c3045a9774c347e774736e.woff") format('woff'),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002686-37380661e5c3045a9774c347e774736e.eot"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002686-37380661e5c3045a9774c347e774736e.eot#iefix") format('embedded-opentype')`,
      },
      {
        fontFamily: 'Amalia',
        src: `url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002687-4bd822b5c242681f6169ad1502713aaa.woff2") format("woff2"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002687-4bd822b5c242681f6169ad1502713aaa.woff") format('woff'),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002687-4bd822b5c242681f6169ad1502713aaa.eot"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002687-4bd822b5c242681f6169ad1502713aaa.eot#iefix") format('embedded-opentype')`,
        fontStyle: 'italic',
      },
      {
        fontFamily: 'Amalia',
        src: `url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002690-a5e3ea4a5f44cf964d20b48a6cc366de.woff2") format("woff2"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002690-a5e3ea4a5f44cf964d20b48a6cc366de.woff") format('woff'),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002690-a5e3ea4a5f44cf964d20b48a6cc366de.eot"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002690-a5e3ea4a5f44cf964d20b48a6cc366de.eot#iefix") format('embedded-opentype')`,
        fontWeight: fontWeight.bold,
      },
      {
        fontFamily: 'Amalia',
        src: `url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002691-3d041a971c518bdfbb0b427be7c570d7.woff2") format("woff2"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002691-3d041a971c518bdfbb0b427be7c570d7.woff") format('woff'),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002691-3d041a971c518bdfbb0b427be7c570d7.eot"),
      url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-031682-010394-002691-3d041a971c518bdfbb0b427be7c570d7.eot#iefix") format('embedded-opentype')`,
        fontWeight: fontWeight.bold,
        fontStyle: 'italic',
      },
    ],
  })
  .attach();
