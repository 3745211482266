import IconEmptyFile from '../CustomIcons/IconEmptyFile';
import IconPDF from '../CustomIcons/IconPDF';
import { convertFileSize } from '../../utils/file.utils';
import { splitTextWithTooltip } from '../../utils/strings.utils';
import useStyles from './Attachment.styles';
import { useWindowSize } from '../../hooks/useWindowSize';
import IconTrash from '../CustomIcons/IconTrash';
import { Loader, color } from '@dovera/design-system';

interface Props {
  canBeDeleted?: boolean;
  id: number | string;
  isLoading?: boolean;
  name: string;
  onClick?: () => void;
  onRemove?: () => void;
  size: number;
  type: 'pdf' | 'image' | 'document' | string;
}

const Attachment = ({
  canBeDeleted,
  id,
  isLoading,
  name,
  onClick,
  onRemove,
  size,
  type,
}: Props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const fileType = name.includes('.')
    ? name.split('.')?.[name.split('.').length - 1]
    : '';
  const textLength = windowSize?.width < 1400 ? 10 : 15;
  const iconType =
    type.includes('pdf') || name.endsWith('.pdf') ? (
      <IconPDF id={`icon-attachment-pdf--${id}`} />
    ) : (
      <IconEmptyFile id={`icon-empty-file--${id}`} />
    );
  return (
    <button
      className={classes.attachment}
      data-testid="attachmentBtn"
      data-type="form"
      disabled={isLoading}
      onClick={() => {
        if (onClick) onClick();
      }}
      type="button"
    >
      <span>{isLoading ? <Loader color="#a7a9ac" size={24} /> : iconType}</span>
      <div className={classes.attachmentBody}>
        <div>
          <b>
            {splitTextWithTooltip(name.split('.')[0], textLength)}.{fileType}
          </b>
          {size > 0 && <span className="d-block">{convertFileSize(size)}</span>}
        </div>
        {canBeDeleted && (
          <span
            className="clickable"
            onClick={onRemove}
            onKeyDown={onRemove}
            role="button"
            tabIndex={0}
          >
            <IconTrash
              color={color('error')}
              id={`icon-trash-attachment--${id}`}
            />
          </span>
        )}
      </div>
    </button>
  );
};

export default Attachment;
