import {
  Button,
  ButtonLink,
  Icon,
  Modal,
  Notification,
  Textarea,
} from '@dovera/design-system';
import { useEffect, useState } from 'react';
import strings from '../../../../../constants/strings';
import {
  PROPOSAL_STATES,
  PROPOSAL_TYPES,
} from '../../../../../types/proposals.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { useField, useForm } from 'react-final-form-hooks';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { addCompletion } from '../../../../../slices/drugProposals.slice';
import moment from 'moment';
import Message from '../../../../spravy/Message/Message';
import useStyles from '../../../Proposals.styles';
import { cx } from '../../../../../utils/exports';
import { useSaveCompletion } from '../../../../../api/drugProposals';
import { Upload } from '../../../../../components/UploadForm';
import { systemConstants } from '../../../../../constants/systemConstants';
import IconAttachment from '../../../../../components/CustomIcons/IconAttachment';
import Section from '../../Content/common/Section';
import { Dokument } from '../../../../../types/models/Dokument';
import { ToastActionType } from '../../../../../types/drugProposals.types';

const texts = strings.proposals.drugs.detail;

const validate = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.answer) errors.answer = strings.validation.required.default;
  return errors;
};

interface Props {
  insureeIdTxs: number;
  proposalCompletionText: string;
  proposalId: number;
  proposalNumber: string;
  proposalType: PROPOSAL_TYPES | string;
  state: PROPOSAL_STATES;
  toastAction: ({ isVisible, onHide, result, text }: ToastActionType) => void;
}

const ProposalCompletion = ({
  insureeIdTxs,
  proposalCompletionText,
  proposalId,
  proposalNumber,
  proposalType,
  state,
  toastAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: PROPOSAL_TYPES.Drugs });
  const [files, setFiles] = useState<Dokument[] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { completions } = useSelector(
    (state: RootState) => state.drugProposals.detail,
  );
  const completion = completions?.[proposalNumber];
  const mutation = useSaveCompletion();
  const { form, handleSubmit } = useForm({
    onSubmit: (values) => {
      mutation.mutate({
        attachments: files?.map((file) => ({
          nazov: file.nazov || '',
          obsah: file.obsah || '',
          typ: file.typ || '',
        })),
        completionPzs: values.answer,
        insureeIdTxs,
        proposalId,
        proposalNumber,
        type: proposalType,
      });
    },
    initialValues: {
      answer: '',
    },
    validate,
  });
  const answer = useField('answer', form);
  useEffect(() => {
    if (mutation.isError || mutation.isSuccess) {
      if (mutation.isSuccess)
        dispatch(
          addCompletion({
            attachments: files?.map((file) => ({
              localAttachment: true,
              nazov: file.nazov || '',
              obsah: file.obsah || '',
              typ: file.typ || '',
            })),
            dateTime: moment().utcOffset(0, true).toISOString(),
            proposalNumber,
            text: answer.input.value,
          }),
        );
      setIsModalVisible(false);
      const result: 'success' | 'error' = mutation.isError
        ? 'error'
        : 'success';
      toastAction({
        isVisible: true,
        result: result || 'success',
        text:
          result === 'error'
            ? texts.toasts.errorCompletion
            : texts.toasts.successCompletion,
      });
    }
    // eslint-disable-next-line
  }, [mutation.isError, mutation.isSuccess]);
  const renderCompletionNtf = state === PROPOSAL_STATES.ToSupplement &&
    !completion && (
      <div
        className={cx('fit-content content-center-vert', classes.proposalNtf)}
      >
        <Notification
          message={
            <>
              {texts.notificaions.toSupplement.actionNeeded.description}
              <Button
                className="text-space-left no-mrg"
                onClick={() => setIsModalVisible(true)}
              >
                {texts.notificaions.toSupplement.actionNeeded.button}
              </Button>
            </>
          }
          variant="warning"
        />
      </div>
    );
  const renderProgressNtf = state === PROPOSAL_STATES.ToSupplement &&
    completion && (
      <div
        className={cx('fit-content content-center-vert', classes.proposalNtf)}
      >
        <Notification
          message={
            <>
              {texts.notificaions.toSupplement.processing.description}
              <Button
                className="text-space-left no-mrg"
                onClick={() => setIsModalVisible(true)}
              >
                {texts.notificaions.toSupplement.processing.button}
              </Button>
            </>
          }
          variant="white"
        />
      </div>
    );
  const renderUserCompletion = completion && (
    <div className={classes.useCompletion}>
      <Message
        attachments={completion?.attachments}
        content={completion?.text}
        date={completion?.dateTime}
        fromDZP={false}
        id={proposalId}
      />
    </div>
  );
  const renderUploadForm = (
    <Upload
      id="proposal-completion--upload"
      labelText={
        <>
          <span className="text-space-half-right">
            <b>Presuňte súbor sem alebo kliknite na</b>
            <br />
            <span className="text-color-grey-light">
              Prílohy nesmú prekročiť 20 MB.
            </span>
          </span>
          <ButtonLink className="no-mrg no-pad" data-type="form">
            <IconAttachment id={`reply-form--attachment`} />
            Priložiť súbor
          </ButtonLink>
        </>
      }
      maxFileSize={systemConstants.MAX_UPLOAD_SIZE}
      onChange={(files) => setFiles(files)}
      // eslint-disable-next-line
      onUploadError={(err) => console.log(err)}
    />
  );
  return (
    <>
      {renderCompletionNtf}
      {renderProgressNtf}
      <Modal
        closeOnOverlayClick={false}
        header={texts.modals.completion.title}
        isVisible={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      >
        {proposalCompletionText && (
          <Section
            className="mb-large"
            extra={<SafeHtml html={proposalCompletionText} wrapper="div" />}
            withBorder
          />
        )}

        {!completion && (
          <form className={classes.completionForm} onSubmit={handleSubmit}>
            <Textarea
              error={
                answer.meta.touched &&
                answer.meta.error && (
                  <SafeHtml html={answer.meta.error} wrapper="span" />
                )
              }
              placeholder={texts.modals.completion.placeholder}
              {...answer.input}
            />
            {renderUploadForm}
            <Button
              className={classes.completionBtn}
              isLoading={mutation.isPending}
              submit
            >
              <Icon
                className="text-space-half-right"
                name="send"
                size="medium"
              />
              {texts.modals.completion.button}
            </Button>
          </form>
        )}
        {renderUserCompletion}
      </Modal>
    </>
  );
};

export default ProposalCompletion;
