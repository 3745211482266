// @ts-nocheck
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import logo from './assets/dovera.png';
import dzpFont from './fonts/DoveraSans-Regular.ttf';
import dzpFontBold from './fonts/DoveraSans-Bold.ttf';
import dzpFontThin from './fonts/DoveraSans-Thin.ttf';
import { color } from '../../constants';
import { EventType } from '../../types/proposals.types';
import { getEventTypeLabel } from '../../utils/spaProposals.utils';
import { formatDate } from '../../utils/date.utils';
import SuccessIcon from './assets/svg/SuccessIcon';
import ErrorIcon from './assets/svg/ErrorIcon';
import { stripHtml } from '../../utils/strings.utils';
import strings from '../../constants/strings';
// @ts-check

Font.register({
  family: 'DoveraSans',
  fonts: [
    {
      format: 'truetype',
      fontWeight: '400',
      src: dzpFont,
    },
    {
      format: 'truetype',
      fontWeight: 'bold',
      src: dzpFontBold,
    },
    {
      format: 'truetype',
      fontWeight: '100',
      src: dzpFontThin,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'DoveraSans',
    fontWeight: 400,
    padding: '20px 40px',
    fontSize: 11,
    lineHeight: 1.5,
    position: 'relative',
  },
  sectionContent: {
    paddingBottom: 20,
  },
  h1: {
    fontWeight: 'bold',
    fontSize: 24,
    color: color('secondary'),
    marginBottom: 20,
    lineHeight: 1,
  },
  h4: {
    fontWeight: 'bold',
    fontSize: 16,
    color: color('secondary'),
    marginBottom: 10,
    lineHeight: 1,
    marginLeft: 5,
  },
  bold: {
    fontWeight: 'bold',
    display: 'inline',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
  },
  errors: {},
  icon: {
    marginRight: 5,
    verticalAlign: 'middle',
  },
  header: {
    padding: '24px 0',
  },
  logo: {
    position: 'absolute',
    width: 71,
    right: 0,
  },
  hr: {
    marginTop: 15,
    paddingBottom: 15,
    display: 'block',
    borderTop: `1px solid ${color('grey', 200)}`,
  },
});

type StatusType = 'completed' | 'error' | 'warning' | null;
type RadioType = 'Y' | 'N' | string | null;

interface Props {
  additionalDiagnoses: {
    additionalDiagnoses: string;
    anamnese: string;
    status: StatusType;
  };
  additionalInfomation: {
    escort: boolean;
    escortHelp: string;
    mobility: string;
    spaType: string;
    status: StatusType;
  };
  dgIg: {
    confirmation: RadioType;
    contraindications: RadioType;
    date: string | null;
    diagnose: string;
    epicrisis: string;
    eventType?: EventType;
    examinations: RadioType;
    iG: string;
    status: StatusType;
    svlz: RadioType;
    txsErrors: string[];
  };
  doctor: {
    ambulance: string;
    email: string;
    name: string;
    status: StatusType;
  };
  patient: {
    insureeId: string;
    isDebtor: boolean;
    name: string;
    status: StatusType;
  };
  pzsName?: string;
}

const NewProposalTemplate = ({
  additionalDiagnoses,
  additionalInfomation,
  dgIg,
  doctor,
  patient,
  pzsName,
}: Props) => {
  const renderFixedHeader = (
    <View fixed>
      <Text>{pzsName || ''}</Text>
      <Image src={logo} style={styles.logo} />
      <Text style={styles.hr} />
    </View>
  );
  const renderTitle = (
    <View>
      <Text style={styles.h1}>Kúpeľný návrh (koncept)</Text>
    </View>
  );
  const renderDoctorSection = (
    <View style={styles.sectionContent}>
      <View style={styles.flex}>
        {doctor.status && (
          <View
            style={
              ([styles.icon],
              { marginTop: patient.status === 'completed' ? -10 : -4 })
            }
          >
            {doctor.status === 'completed' ? <SuccessIcon /> : <ErrorIcon />}
          </View>
        )}
        <Text style={styles.h4}>Navrhujúci lekár</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Lekár: </Text>
        <Text>{doctor.name}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Ambulancia: </Text>
        <Text>{doctor.ambulance}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>E-mail: </Text>
        <Text>{doctor.email}</Text>
      </View>
    </View>
  );
  const renderPatientSection = (
    <View style={styles.sectionContent}>
      <View style={styles.flex}>
        {patient.status && (
          <View
            style={
              ([styles.icon],
              {
                marginTop:
                  patient.status === 'completed' && !patient.isDebtor
                    ? -10
                    : -4,
              })
            }
          >
            {patient.status === 'completed' && !patient.isDebtor ? (
              <SuccessIcon />
            ) : (
              <ErrorIcon />
            )}
          </View>
        )}
        <Text style={styles.h4}>Informácie o pacientovi</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Rodné číslo: </Text>
        <Text>{patient.insureeId}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Meno a priezvisko: </Text>
        <Text>{patient.name}</Text>
      </View>
      <View
        style={[
          styles.flex,
          { color: patient.isDebtor ? color('error') : 'black' },
        ]}
      >
        <Text style={styles.bold}>Dlžník: </Text>
        <Text>
          {patient.isDebtor
            ? stripHtml(
                strings.proposals.new.notifications.errors.txsControls.Dlznik,
              )
            : 'Nie'}
        </Text>
      </View>
    </View>
  );
  const renderDgIsSection = () => {
    const isErrorStep: boolean =
      dgIg.confirmation === 'N' ||
      dgIg.contraindications === 'Y' ||
      dgIg.examinations === 'N' ||
      dgIg.svlz === 'N' ||
      dgIg.txsErrors.length > 0;
    return (
      <View style={styles.sectionContent}>
        <View style={styles.flex}>
          {dgIg.status && (
            <View
              style={
                ([styles.icon],
                {
                  marginTop:
                    patient.status === 'completed' || !isErrorStep ? -10 : -4,
                })
              }
            >
              {dgIg.status === 'completed' || !isErrorStep ? (
                <SuccessIcon />
              ) : (
                <ErrorIcon />
              )}
            </View>
          )}
          <Text style={styles.h4}>Diagnóza a indikačná skupina</Text>
        </View>
        <View style={styles.flex}>
          <Text style={styles.bold}>Indikačná skupina: </Text>
          <Text>{dgIg.iG}</Text>
        </View>
        <View style={styles.flex}>
          <Text style={styles.bold}>Diagnóza: </Text>
          <Text>{dgIg.diagnose}</Text>
        </View>
        {dgIg.confirmation && (
          <View
            style={[
              styles.flex,
              { color: dgIg.confirmation === 'N' ? color('error') : 'black' },
            ]}
          >
            <Text style={styles.bold}>Potvrdenie od ošetrujúceho lekára: </Text>
            <Text>{dgIg.confirmation === 'Y' ? 'Áno' : 'Nie'}</Text>
          </View>
        )}
        {!!(dgIg.date && getEventTypeLabel(dgIg.eventType)) && (
          <View style={styles.flex}>
            <Text style={styles.bold}>
              {`${getEventTypeLabel(dgIg.eventType, true)}: `}
            </Text>
            <Text>{formatDate(dgIg.date)}</Text>
          </View>
        )}
        {dgIg.contraindications && (
          <View
            style={[
              styles.flex,
              {
                color:
                  dgIg.contraindications === 'Y' ? color('error') : 'black',
              },
            ]}
          >
            <Text style={styles.bold}>Kontraindikácie: </Text>
            <Text>{dgIg.contraindications === 'Y' ? 'Má' : 'Nemá'}</Text>
          </View>
        )}
        {dgIg.examinations && (
          <View
            style={[
              styles.flex,
              { color: dgIg.examinations === 'N' ? color('error') : 'black' },
            ]}
          >
            <Text style={styles.bold}>Vyšetrenia: </Text>
            <Text>{dgIg.examinations === 'Y' ? 'Áno' : 'Nie'}</Text>
          </View>
        )}
        {dgIg.svlz && (
          <View
            style={[
              styles.flex,
              { color: dgIg.svlz === 'N' ? color('error') : 'black' },
            ]}
          >
            <Text style={styles.bold}>
              Klinické a SVLZ vyšetrenia nie staršie ako 6 mesiacov:{' '}
            </Text>
            <Text>{dgIg.svlz === 'Y' ? 'Áno' : 'Nie'}</Text>
          </View>
        )}
        <View style={styles.flex}>
          <Text style={styles.bold}>Epikríza: </Text>
          <Text>{dgIg.epicrisis}</Text>
        </View>
        <View style={styles.errors}>
          {dgIg.txsErrors.map((t) => (
            <View key={`txs-error--${t}`}>
              <Text style={{ color: color('error') }}>{stripHtml(t)}</Text>
            </View>
          ))}
        </View>
      </View>
    );
  };
  const renderObjectiveFindingsSection = (
    <View style={styles.sectionContent}>
      <View style={styles.flex}>
        {additionalDiagnoses.status && (
          <View
            style={
              ([styles.icon],
              { marginTop: patient.status === 'completed' ? -10 : -4 })
            }
          >
            {additionalDiagnoses.status === 'completed' ? (
              <SuccessIcon />
            ) : (
              <ErrorIcon />
            )}
          </View>
        )}
        <Text style={styles.h4}>Objektívny nález a vedľajšie diagnózy</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>
          Anamnéza, patologický a objektívny nález:{' '}
        </Text>
        <Text>{additionalDiagnoses.anamnese || '-'}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Vedľajšie diagnózy: </Text>
        <Text>{additionalDiagnoses.additionalDiagnoses || '-'}</Text>
      </View>
    </View>
  );
  const renderAdditionalInfoSection = (
    <View style={styles.sectionContent}>
      <View style={styles.flex}>
        {additionalInfomation.status && (
          <View
            style={
              ([styles.icon],
              { marginTop: patient.status === 'completed' ? -10 : -4 })
            }
          >
            {additionalInfomation.status === 'completed' ? (
              <SuccessIcon />
            ) : (
              <ErrorIcon />
            )}
          </View>
        )}
        <Text
          style={[
            styles.h4,
            { marginLeft: !additionalInfomation.status ? 0 : 5 },
          ]}
        >
          Doplňujúce informácie
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Kúpeľná starostlivosť: </Text>
        <Text>{additionalInfomation.spaType}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>Sprievod: </Text>
        <Text>{additionalInfomation.escort ? 'Áno' : 'Nie'}</Text>
      </View>
      {additionalInfomation.escort && (
        <View style={styles.flex}>
          <Text style={styles.bold}>Sprievod bude pomáhať s: </Text>
          <Text>{additionalInfomation.escortHelp || '-'}</Text>
        </View>
      )}
      <View style={styles.flex}>
        <Text style={styles.bold}>Pohyblivosť pacienta: </Text>
        <Text>{additionalInfomation.mobility}</Text>
      </View>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {renderFixedHeader}
        {renderTitle}
        {renderDoctorSection}
        {renderPatientSection}
        {renderDgIsSection()}
        {renderObjectiveFindingsSection}
        {renderAdditionalInfoSection}
      </Page>
    </Document>
  );
};

export default NewProposalTemplate;
