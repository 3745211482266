import {
  ButtonLink,
  Icon,
  Loader,
  Notification,
  Skeleton,
} from '@dovera/design-system';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import strings from '../../../../../constants/strings';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { RootState } from '../../../../../rootReducer';
import { fetchObsahDokumentu } from '../../../../../slices/dokumenty.slice';
import { GetProposalDetailDocumentsSPAResponse } from '../../../../../types/spaProposals.types';
import { cx } from '../../../../../utils/exports';
import useStyles from '../../../Proposals.styles';
import Section from './Section';
import { UseQueryResult } from '@tanstack/react-query';
import { GetProposalDetailDocumentsResponse } from '../../../../../types/drugProposals.types';
import { Document } from '../../../../../types/models/Dokument';
import { safeString } from '../../../../../utils/strings.utils';

const texts = strings.proposals.spa.detail;

interface Props {
  data?: GetProposalDetailDocumentsSPAResponse;
  documents?: UseQueryResult<GetProposalDetailDocumentsResponse, Error>;
  proposalId: number;
}

const Documents = ({ data, documents, proposalId }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });

  const docs: Document[] = data
    ? data.dokumenty.map((d) => ({
        barCode: safeString(d.ciarovyKod),
        name: safeString(d.nazov),
        validatedCode: safeString(d.overovaciKod),
      }))
    : documents?.data?.documents || [];

  const dispatch = useAppDispatch();
  const fetchedDocuments = useSelector(
    (state: RootState) => state.dokumenty.dokumenty,
  );

  const getDocument = useCallback(
    (document: string, barCode: boolean) => {
      if (document)
        dispatch(
          fetchObsahDokumentu(
            barCode
              ? { dms: { ciarovyKod: document } }
              : { navrh: { idNavrhNaZS: proposalId, overovaciKod: document } },
            true,
            false,
          ),
        );
    },
    [dispatch, proposalId],
  );

  if (documents?.isFetching)
    return (
      <Section
        extra={
          <>
            <Skeleton className="mb-small" width="80%" />
            <Skeleton className="mb-small" width="60%" />
            <Skeleton className="mb-small" width="80%" />
          </>
        }
        title={texts.subtitles.documents}
      />
    );

  if (documents?.isError)
    return (
      <Section
        extra={
          <Notification
            message={
              strings.proposals.drugs.detail.notificaions.notLoadedDocuments
            }
            variant="warning"
          />
        }
        title={texts.subtitles.documents}
      />
    );

  return (
    <Section
      extra={docs.map((d, key) => {
        const documentCode: string = d.barCode || d.validatedCode || '';
        if (documentCode) {
          return (
            <ButtonLink
              key={`proposal-document-${key}`}
              className={cx(classes.documentLink, 'no-mrg no-pad text-left')}
              isDisabled={!!fetchedDocuments[documentCode]?.isLoading}
              onClick={() => getDocument(documentCode, !!d.barCode)}
            >
              {fetchedDocuments[documentCode]?.isLoading ? (
                <Loader className="text-space-right" size={16} />
              ) : (
                <Icon name="file-blank" />
              )}
              {d.name}
            </ButtonLink>
          );
        }
        return <span />;
      })}
      title={texts.subtitles.documents}
    />
  );
};

export default Documents;
