import { useNavigate } from 'react-router';
import strings from '../../../constants/strings';
import { useFetchProposalsList } from '../../../api/drugProposals';
import {
  PROPOSAL_TYPES,
  ProposalsFilter,
} from '../../../types/proposals.types';
import {
  formatNameStr,
  hotjarMasking,
  safeString,
  splitTextWithTooltip,
} from '../../../utils/strings.utils';
import { formatDate } from '../../../utils/date.utils';
import { ButtonLink, Icon, Pagination, TableApp } from '@dovera/design-system';
import { navrhyRoutes } from '../../../routes';
import { getStateProperties } from '../../../utils/proposals.utils';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { useEffect, useState } from 'react';
import { TABLE_PAGINATION } from '../../../constants/misc';
import { useField, useForm } from 'react-final-form-hooks';
import useStyles from '../Proposals.styles';
import { NoDataIllustration } from '../../../components/EmptyState';
import moment from 'moment';
import { scrollToTop } from '../../../utils/app.utils';
import { useDebounce } from '../../../hooks/useDebounce';
import Filter from './Filter/Filter';

const texts = strings.proposals.common.submittedProposals;

interface Props {
  proposalType: PROPOSAL_TYPES | string;
  shouldReload: boolean;
}

const TableLN = ({ proposalType, shouldReload }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles({ proposalType: PROPOSAL_TYPES.Drugs });
  const [actualPage, setActualPage] = useState(0);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [filter, setFilter] = useState<ProposalsFilter | undefined>(undefined);
  const { form } = useForm({
    onSubmit: () => {},
    initialValues: {
      search: '',
      state: '',
      validityFrom: '',
      validityTo: '',
    },
  });
  const searchField = useField('search', form);
  const stateField = useField('state', form);
  const validityFromField = useField('validityFrom', form);
  const validityToField = useField('validityTo', form);

  const searchDebounced = useDebounce(searchField.input.value, 1000);

  const { data, isError, isFetching, refetch } = useFetchProposalsList(
    {
      filter,
      limit: TABLE_PAGINATION,
      listType: proposalType,
      offset: actualPage ? actualPage * TABLE_PAGINATION : 0,
      reloadListFromTXS: false,
    },
    {
      enabled: initialLoaded,
    },
  );
  useEffect(() => {
    setActualPage(0);
    setFilter({
      search: searchDebounced || undefined,
      proposalState:
        stateField.input.value && stateField.input.value !== 'all'
          ? stateField.input.value
          : undefined,
      dateFrom: moment(validityFromField.input.value).isValid()
        ? validityFromField.input.value
        : undefined,
      dateTo: moment(validityToField.input.value).isValid()
        ? validityToField.input.value
        : undefined,
    });
  }, [
    searchDebounced,
    stateField.input.value,
    validityFromField.input.value,
    validityToField.input.value,
  ]);
  useEffect(() => {
    if (shouldReload) {
      navigate(`${navrhyRoutes.zoznamPodanychNavrhov}?typ=${proposalType}`);
      refetch();
    }
  }, [navigate, proposalType, refetch, shouldReload]);
  useEffect(() => {
    setTimeout(() => {
      setInitialLoaded(true);
    }, 1000);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    scrollToTop();
  }, [actualPage]);
  const renderFilter = (
    <Filter
      search={searchField}
      state={stateField}
      type={proposalType}
      validityFrom={validityFromField}
      validityTo={validityToField}
    />
  );
  const renderTable = (
    <TableApp
      caption=""
      className={classes.listTable}
      columns={[
        {
          accessor: 'col1',
          Header: texts.labels.proposalNumber,
        },
        {
          accessor: 'col2',
          Header: texts.labels.insuree,
        },
        {
          accessor: 'col3',
          Header: texts.labels.doctorCode,
        },
        {
          accessor: 'col4',
          Header: texts.labels.product,
        },
        {
          accessor: 'col5',
          Header: texts.labels.validity,
        },
        {
          accessor: 'col6',
          Header: texts.labels.state,
        },
        {
          accessor: 'col7',
          Header: '',
        },
      ]}
      data={
        data?.proposals
          ? data?.proposals?.map((d) => {
              const stateProperties = getStateProperties(d.state);
              return {
                col1: (
                  <ButtonLink
                    className="inline-btn text-color-black text-thin"
                    onClick={() =>
                      navigate(
                        navrhyRoutes.detail({
                          proposalId: d.proposalId,
                          proposalNumber: d.proposalNumber,
                          type: proposalType,
                        }),
                      )
                    }
                  >
                    {d.proposalNumber}
                  </ButtonLink>
                ),
                col2: (
                  <>
                    {hotjarMasking(d.insuree.insureeId, 'b', 'd-block')}
                    {splitTextWithTooltip(
                      formatNameStr(
                        `${d.insuree.personName} ${d.insuree.personSurname}`,
                      ),
                      16,
                      '',
                      true,
                      'd-block fs-14 text-color-grey-light',
                    )}
                  </>
                ),
                col3: (
                  <>
                    {hotjarMasking(d.doctor.code, 'b', 'd-block')}
                    {splitTextWithTooltip(
                      formatNameStr(
                        `${d.doctor.titleBefore ? `${d.doctor.titleBefore} ` : ''}${d.doctor.personName} ${d.doctor.personSurname}${d.doctor.titleAfter ? ` ${d.doctor.titleAfter}` : ''}`,
                      ),
                      16,
                      '',
                      true,
                      'd-block fs-14 text-color-grey-light',
                    )}
                  </>
                ),
                col4: splitTextWithTooltip(
                  d.productCode
                    ? `${d.productCode} - ${safeString(d.productName)}`
                    : strings.undefined,
                  12,
                ),
                col5: (
                  <>
                    <span className="d-block">
                      {formatDate(d.proposalValidityFrom)}
                    </span>
                    <span className="d-block">
                      {formatDate(d.proposalValidityTo)}
                    </span>
                  </>
                ),
                col6: (
                  <div className="d-flex">
                    <Icon
                      color={stateProperties.color}
                      name={stateProperties.name}
                      size="medium"
                    />
                    <span className="text-space-half-left">
                      {stateProperties.text}
                    </span>
                  </div>
                ),
                col7: (
                  <ButtonLink
                    className="inline-btn"
                    onClick={() =>
                      navigate(
                        navrhyRoutes.detail({
                          proposalId: d.proposalId,
                          proposalNumber: d.proposalNumber,
                          type: proposalType,
                        }),
                      )
                    }
                  >
                    Detail
                  </ButtonLink>
                ),
              };
            })
          : []
      }
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={Math.ceil(Number(data?.count) / TABLE_PAGINATION)}
        />
      }
    />
  );
  if (isFetching || !initialLoaded)
    return (
      <>
        {initialLoaded && renderFilter}
        <TablePreloaderSkeleton
          columns={7}
          hasFilter={!initialLoaded}
          rows={5}
        />
      </>
    );
  if (!data?.count || isError)
    return (
      <>
        {renderFilter}
        <NoDataIllustration title={texts.emptyState} />
      </>
    );
  return (
    <>
      {renderFilter}
      {renderTable}
    </>
  );
};

export default TableLN;
