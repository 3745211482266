// @ts-nocheck
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import logo from './assets/dovera.png';
import dzpFont from './fonts/DoveraSans-Regular.ttf';
import dzpFontBold from './fonts/DoveraSans-Bold.ttf';
import dzpFontThin from './fonts/DoveraSans-Thin.ttf';
import { color } from '../../constants';
import { GetProposalDetailResponse } from '../../types/spaProposals.types';
import { formatDate } from '../../utils/date.utils';
import SuccessIcon from './assets/svg/SuccessIcon';
import ErrorIcon from './assets/svg/ErrorIcon';
import {
  addressLowerCases,
  formatNameStr,
  formatPhone,
} from '../../utils/strings.utils';
import WarningIcon from './assets/svg/WarningIcon';
import InfoIcon from './assets/svg/InfoIcon';
import strings from '../../constants/strings';
// @ts-check

Font.register({
  family: 'DoveraSans',
  fonts: [
    {
      format: 'truetype',
      fontWeight: '400',
      src: dzpFont,
    },
    {
      format: 'truetype',
      fontWeight: 'bold',
      src: dzpFontBold,
    },
    {
      format: 'truetype',
      fontWeight: '100',
      src: dzpFontThin,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'DoveraSans',
    fontWeight: 400,
    padding: '20px 40px',
    fontSize: 11,
    lineHeight: 1.5,
    position: 'relative',
  },
  sectionContent: {
    paddingBottom: 20,
  },
  h1: {
    fontWeight: 'bold',
    fontSize: 24,
    color: color('secondary'),
    marginBottom: 20,
    lineHeight: 1,
  },
  h4: {
    fontWeight: 'bold',
    fontSize: 16,
    color: color('secondary'),
    marginBottom: 10,
    lineHeight: 1,
  },
  h4Margin: {
    marginLeft: 5,
  },
  bold: {
    fontWeight: 'bold',
    display: 'inline',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    marginBottom: 5,
  },
  errors: {},
  icon: {
    marginRight: 5,
    verticalAlign: 'middle',
  },
  header: {
    padding: '24px 0',
  },
  logo: {
    position: 'absolute',
    width: 71,
    right: 0,
  },
  hr: {
    marginTop: 15,
    paddingBottom: 15,
    display: 'block',
    borderTop: `1px solid ${color('grey', 200)}`,
  },
  tag: {
    maxWidth: '70%',
    color: '#6a7070',
    border: '1px solid #e4e5e5',
    wordBreak: 'break-word',
    padding: '4px 8px 3px',
    borderRadius: 8,
    marginBottom: 10,
  },
});

const texts = strings.proposals.spa.detail;

interface Props {
  data: GetProposalDetailResponse;
  proposalNumber: string;
  proposalPin?: string | null;
  pzsName?: string;
}

const getProposalState = (state: 'S' | 'N' | 'D' | string) => {
  switch (state) {
    case 'N':
      return (
        <View style={styles.flex}>
          <View style={{ marginTop: -5 }}>
            <ErrorIcon />
          </View>
          <Text style={[styles.h4, styles.h4Margin]}>Neschválený</Text>
        </View>
      );
    case 'S':
      return (
        <View style={styles.flex}>
          <View style={{ marginTop: -7 }}>
            <SuccessIcon />
          </View>
          <Text style={[styles.h4, styles.h4Margin]}>Schválený</Text>
        </View>
      );
    case 'D':
      return (
        <View style={styles.flex}>
          <View style={{ marginTop: -7 }}>
            <WarningIcon />
          </View>
          <Text style={[styles.h4, styles.h4Margin]}>Na doplnenie</Text>
        </View>
      );
    default:
      return (
        <View style={styles.flex}>
          <View style={{ marginTop: -7 }}>
            <InfoIcon />
          </View>
          <Text style={[styles.h4, styles.h4Margin]}>Spracováva sa</Text>
        </View>
      );
  }
};

const ProposalDetailTemplate = ({
  data,
  proposalNumber,
  proposalPin,
  pzsName,
}: Props) => {
  const { detail, doplnujuceInformacie, informacie, navrhujuciLekar, pacient } =
    data;
  const renderFixedHeader = (
    <View fixed>
      <Text>{pzsName || ''}</Text>
      <Image src={logo} style={styles.logo} />
      <Text style={styles.hr} />
    </View>
  );
  const renderTitle = (
    <View>
      <Text style={styles.h1}>Návrh č. {proposalNumber}</Text>
    </View>
  );
  const renderHeadSection = detail && (
    <View
      style={[
        styles.sectionContent,
        {
          backgroundColor: 'rgb(232, 242, 254)',
          padding: 15,
          marginBottom: 15,
        },
      ]}
    >
      {getProposalState(detail.stav)}
      <View style={styles.flex}>
        <Text style={styles.bold}>
          {texts.labels.exposed(detail.typVystavenia)}:{' '}
        </Text>
        <Text>
          {detail.vystaveny ? formatDate(detail.vystaveny) : strings.undefined}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.accepted}: </Text>
        <Text>
          {detail.prijaty ? formatDate(detail.prijaty) : strings.undefined}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.resultDate}: </Text>
        <Text>
          {detail.rozhodnuty
            ? formatDate(detail.rozhodnuty)
            : strings.undefined}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.validity}: </Text>
        <Text>
          {`${formatDate(detail.platnostNavrhuOd || '')} - ${formatDate(
            detail.platnostNavrhuDo || '',
          )}`}
        </Text>
      </View>
      {detail.rezervaciaNavrhu.rezervaciaKupelov && (
        <View style={styles.flex}>
          <Text style={styles.bold}>{texts.labels.reservation}: </Text>
          <Text>{detail.rezervaciaNavrhu.rezervaciaKupelov}</Text>
        </View>
      )}
      {proposalPin && (
        <View style={styles.flex}>
          <Text style={styles.bold}>{texts.labels.pin}: </Text>
          <Text>{proposalPin}</Text>
        </View>
      )}
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.insuranceStatemenet}: </Text>
      </View>
      <View break style={{ maxWidth: '95%' }}>
        {detail.vyjadreniePoistovne ? (
          detail.vyjadreniePoistovne?.split('\n')?.map((d) => (
            <View
              key={`vyjadrenie-poistovne--${d}`}
              style={{
                flexDirection: 'row',
                marginBottom: 4,
                wordBreak: 'break-word',
              }}
            >
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text>{d}</Text>
            </View>
          ))
        ) : (
          <Text>{!detail.vyjadreniePoistovne && strings.undefined}</Text>
        )}
      </View>

      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.treatment}: </Text>
        <Text>{detail.kupelnaLiecba || strings.undefined}</Text>
      </View>
    </View>
  );
  const renderPatientSection = (
    <View style={[styles.sectionContent]}>
      <View style={styles.flex}>
        <Text style={styles.h4}>Pacient</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.name}: </Text>
        <Text>{formatNameStr(pacient.meno)}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.insureeId}: </Text>
        <Text>{pacient.rodneCislo}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.address}: </Text>
        <Text>{addressLowerCases(pacient.adresa, true)}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.mobile}: </Text>
        <Text>
          {pacient.mobil ? formatPhone(pacient.mobil, true) : strings.undefined}
        </Text>
      </View>
    </View>
  );
  const renderDoctorSection = (
    <View style={[styles.sectionContent, { margTop: 15 }]}>
      <View style={styles.flex}>
        <Text style={styles.h4}>Navrhujúci lekár</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.doctor}: </Text>
        <Text>{formatNameStr(navrhujuciLekar.lekar)}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.hospital}: </Text>
        <Text>{formatNameStr(navrhujuciLekar.nemocnica)}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.ambulance}: </Text>
        <Text>
          {formatNameStr(navrhujuciLekar.ambulancia) || strings.undefined}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.email}: </Text>
        <Text>{navrhujuciLekar.email || strings.undefined}</Text>
      </View>
    </View>
  );
  const renderInformation = (
    <View style={[styles.sectionContent, { margTop: 15 }]}>
      <View style={styles.flex}>
        <Text style={styles.h4}>
          Informácie o návrhu o zdravotnú starostlivosť
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.diagnose}: </Text>
        <Text>{informacie.diagnoza}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.indicatorGroup}: </Text>
        <Text>{informacie.indikacnaSkupina}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.aproove}: </Text>
        <Text>{informacie.priznakVysetrenieNad70R ? 'Áno' : 'Nie'}</Text>
      </View>
      {informacie.datumUdalosti && (
        <View style={styles.flex}>
          <Text style={styles.bold}>
            {texts.labels.event(informacie.typUdalosti)}:{' '}
          </Text>
          <Text>{formatDate(informacie.datumUdalosti)}</Text>
        </View>
      )}
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.contraindications}: </Text>
        <Text>{informacie.priznakKontraindikacie ? 'Má' : 'Nemá'}</Text>
      </View>
      <View style={[styles.flex, { marginBottom: 15 }]}>
        <Text style={styles.bold}>{texts.labels.examinations}: </Text>
        <Text>{informacie.priznakVsetkyVysetrenia ? 'Áno' : 'Nie'}</Text>
      </View>
      {informacie.vsetkyVysetrenia && (
        <View>
          {informacie.vsetkyVysetrenia.split('\n').map((v, key) => (
            <View key={`proposal-examination--${key}`} style={styles.tag}>
              <Text>{v}</Text>
            </View>
          ))}
        </View>
      )}
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.svlz3Month}: </Text>
        <Text>
          {informacie.priznakKlinALabVysetreniaNieViacAko3M ? 'Áno' : 'Nie'}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.epicrisis}: </Text>
        <Text>{informacie.epikriza || strings.undefined}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.anannese}: </Text>
        {!informacie.anamneza && !informacie.objektivnyNalez ? (
          strings.undefined
        ) : (
          <View>
            <View>
              <Text>{informacie.objektivnyNalez || ''}</Text>
            </View>
            <View>
              <Text>{informacie.anamneza || ''}</Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.additionalDiagnoses}: </Text>
        <Text>{informacie.vedlajsiaDiagnoza || strings.undefined}</Text>
      </View>
    </View>
  );
  const renderAdditionalInfo = (
    <View style={[styles.sectionContent, { margTop: 15 }]}>
      <View style={styles.flex}>
        <Text style={styles.h4}>Doplňujúce informácie</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.spaType}: </Text>
        <Text>
          {doplnujuceInformacie.kupelnaStarostlivost || strings.undefined}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.escort}: </Text>
        <Text>{doplnujuceInformacie.sprievod ? 'Áno' : 'Nie'}</Text>
      </View>
      {doplnujuceInformacie.sprievod && doplnujuceInformacie.dovodSprievodu && (
        <View style={styles.flex}>
          <Text style={styles.bold}>{texts.labels.escortType}: </Text>
          <Text>
            {doplnujuceInformacie.dovodSprievodu.split(';').join(', ')}
          </Text>
        </View>
      )}
      <View style={styles.flex}>
        <Text style={styles.bold}>{texts.labels.mobility}: </Text>
        <Text>
          {doplnujuceInformacie.pohyblivostPacienta || strings.undefined}
        </Text>
      </View>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {renderFixedHeader}
        {renderTitle}
        {renderHeadSection}
        {renderPatientSection}
        {renderDoctorSection}
        {renderInformation}
        {renderAdditionalInfo}
      </Page>
    </Document>
  );
};

export default ProposalDetailTemplate;
