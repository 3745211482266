import { Grid, GridCol } from '@dovera/design-system';
import {
  DetailDoctor,
  DetailPatient,
  DetailProposalData,
  DetailProposalInformation,
  GetProposalDetailDocumentsResponse,
} from '../../../../types/drugProposals.types';
import Patient from './common/Patient';
import Doctor from './common/Doctor';
import Section from './common/Section';
import strings from '../../../../constants/strings';
import { formatDate } from '../../../../utils/date.utils';
import { safeString } from '../../../../utils/strings.utils';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { UseQueryResult } from '@tanstack/react-query';
import Documents from './common/Documents';

const texts = strings.proposals.drugs.detail;

interface Props {
  detail: DetailProposalData;
  documents: UseQueryResult<GetProposalDetailDocumentsResponse, Error>;
  information: DetailProposalInformation;
  patient: DetailPatient;
  proposalDoctor: DetailDoctor;
  proposalId: number;
}

const Information = ({
  detail,
  information,
}: {
  detail: DetailProposalData;
  information: DetailProposalInformation;
}) => {
  const {
    codeIL,
    diagnosisCode,
    diagnosisName,
    dosage,
    epicrisis,
    hasContraintication,
    nameIL,
    productCode,
    productName,
    quantity,
    treatmentJustification,
  } = information;
  return (
    <Section
      rows={[
        {
          label: texts.labels.product,
          value: `${productCode}${productName ? ` - ${productName}` : ''}`,
        },
        {
          label: texts.labels.diagnose,
          value: `${diagnosisCode}${diagnosisName ? ` - ${diagnosisName}` : ''}`,
        },
        {
          label: texts.labels.period,
          value: `${formatDate(detail.proposalValidityFrom)} - ${formatDate(detail.proposalValidityTo)}`,
        },
        {
          label: texts.labels.quantity,
          value: safeString(quantity),
        },
        {
          label: texts.labels.dosage,
          value: safeString(dosage),
        },
        {
          label: texts.labels.indicativeLimitations,
          value: safeString(
            codeIL && `${codeIL}${nameIL ? ` - ${nameIL}` : ''}`,
            'Nemá',
          ),
        },
        {
          label: texts.labels.contraindications,
          value: safeString(hasContraintication && 'Má', 'Nemá'),
        },
        {
          label: texts.labels.epicrisis,
          value: <SafeHtml html={epicrisis || strings.undefined} />,
        },
        {
          label: texts.labels.reason,
          value: (
            <SafeHtml html={treatmentJustification || strings.undefined} />
          ),
        },
      ]}
      title={texts.subtitles.information}
      withBorder
    />
  );
};

const ContentLN = ({
  detail,
  documents,
  information,
  patient,
  proposalDoctor,
  proposalId,
}: Props) => (
  <Grid className="mb-xlarge">
    <GridCol size={6}>
      <Patient patient={patient} />
    </GridCol>
    <GridCol size={6}>
      <Doctor proposalDoctor={proposalDoctor} />
    </GridCol>
    <GridCol size={6}>
      <Information detail={detail} information={information} />
    </GridCol>
    <GridCol size={6}>
      <Documents documents={documents} proposalId={proposalId} />
    </GridCol>
  </Grid>
);

export default ContentLN;
