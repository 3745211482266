import { Grid, GridCol, Notification, Skeleton } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import {
  GetProposalDetailDocumentsSPAResponse,
  GetProposalDetailResponse,
} from '../../../../types/spaProposals.types';
import AdditionalInfo from './common/AdditionalInfo';
import Doctor from './common/Doctor';
import Documents from './common/Documents';
import Information from './common/Information';
import Patient from './common/Patient';

const texts = strings.proposals.spa.detail;

interface Props {
  data: GetProposalDetailResponse;
  documents: {
    data: GetProposalDetailDocumentsSPAResponse | null;
    error: string | null;
    isLoading: boolean;
  };
  proposalId: number;
}

const ContentKN = ({ data, documents, proposalId }: Props) => {
  const { doplnujuceInformacie, informacie, navrhujuciLekar, pacient } = data;

  return (
    <>
      <Grid className="mb-xlarge">
        <GridCol size={6}>
          <Patient
            patient={{
              address: pacient.adresa,
              dateOfBirth: pacient.datumNarodenia,
              insureeId: pacient.rodneCislo,
              insureeIdTxs: pacient.poistenecId,
              mobile: pacient.mobil,
              personName: pacient.meno,
              sex: pacient.pohlavie,
            }}
          />
        </GridCol>
        <GridCol size={6}>
          <Doctor
            proposalDoctor={{
              ambulance: navrhujuciLekar.ambulancia,
              doctor: navrhujuciLekar.lekar,
              email: navrhujuciLekar.email,
              hospital: navrhujuciLekar.nemocnica,
            }}
          />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={6}>
          <Information informacie={informacie} />
        </GridCol>
        <GridCol size={6}>
          <AdditionalInfo doplnujuceInformacie={doplnujuceInformacie} />
          <div className="mb-xlarge" />
          {documents.data?.dokumenty && (
            <Documents data={documents.data} proposalId={proposalId} />
          )}
          {documents.isLoading && <Loader />}
          {documents.error && <Error error={documents.error} />}
        </GridCol>
      </Grid>
    </>
  );
};

const Loader = () => (
  <>
    <h4 className="mb-small">
      <Skeleton width="50%" />
    </h4>
    <span className="d-block mb-small">
      <Skeleton width="40%" />
    </span>
  </>
);

const Error = ({ error }: { error: string }) => (
  <>
    <h4 className="mb-small">{texts.subtitles.documents}</h4>
    <Notification message={error} variant="error" />
  </>
);

export default ContentKN;
