import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckProposalDataPayload,
  CheckProposalDataResponse,
  DrugProduct,
  GetCnpDetailResponse,
  GetDiagnosesQueries,
  GetDrugsCnpDetailQueries,
  GetDrugsCnpDocumentsQueries,
  GetDrugsDetailResponse,
  GetFormDataQueries,
  GetFormDataResponse,
  GetListOfAvailableDoctorsQueries,
  GetListOfAvailableDoctorsResponse,
  GetProductsQueries,
  GetProductsResponse,
  GetProposalDetailDocumentsResponse,
  GetProposalsListQueries,
  GetProposalsListResponse,
  SaveCompletionRequest,
  SaveCompletionResponse,
  SaveProposalPayload,
  SaveProposalResponse,
} from '../types/drugProposals.types';
import { createQueryParams } from '../utils/api.utils';
import { Choice } from 'choices.js';
import store from '../store';
import { setLoadedCNP, setProducts } from '../slices/drugProposals.slice';
import {
  GetDiagnosesListReponse,
  PROPOSAL_TYPES,
  ProposalDiagnose,
} from '../types/proposals.types';
import { convertPayloadToSK } from '../translations/utils';
import {
  convertCheckProposal,
  convertGetCnpDetail,
  convertGetDurgsDetail,
  convertGetProoposalsList,
  convertGetProposalDetailDocuments,
  convertSaveCompletionResponse,
} from '../translations/apiResponses/drugProposals';
import {
  CheckProposalDataResponseSK,
  GetCnpDetailResponseSK,
  GetDrugsDetailResponseSK,
  GetProposalDetailDocumentsResponseSK,
  GetProposalsListResponseSK,
  SaveCompletionResponseSK,
} from '../translations/apiTypes/drugProposals.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { WriteResponse } from '../types/api';

const API_CONTROLLER = `LnCnp`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const getListOfAvailableDoctors = createAsyncThunk(
  'drugProposals/getListOfAvailableDoctors',
  async (queries: GetListOfAvailableDoctorsQueries) => {
    const { data }: AxiosResponse<GetListOfAvailableDoctorsResponse> =
      await axios.get(
        `${API_URL}DajZoznamDostupnychLekarov${createQueryParams({
          withoutEmpty: false,
          queryParams: {
            ...queries,
          },
        })}`,
      );
    return data;
  },
);

export const checkProposalData = createAsyncThunk(
  'drugProposals/checkDrugProposalData',
  async (
    payload: CheckProposalDataPayload,
  ): Promise<CheckProposalDataResponse> => {
    const url = `${API_URL}SkontrolujUdajeLiekovehoNavrhu`;
    const globalState = store.getState().drugProposals.new.stepper;
    const globalStateForm = store.getState().drugProposals.new.data.formData;
    const prevData: CheckProposalDataPayload = {
      diagnosisCode: globalState.step3.diagnose?.kod || '',
      checkTypes: [],
      proposingDoctor: {
        id: globalState.step1.doctor.id || 0,
        code: globalState.step1.doctor.code || '',
        expertise: globalState.step1.doctor.expertise || '',
      },
      proposingExpertise: {
        id: globalState.step1.ambulance.id || 0,
        code: globalState.step1.ambulance.code || '',
        expertise: globalState.step1.ambulance.expertise || '',
      },
      productCode: globalState.step3.product?.code || '',
      insureeId: globalState.step2.insureeId || '',
      formId: globalStateForm.formId || '',
    };
    const req: CheckProposalDataPayload = {
      ...prevData,
      ...payload,
      checkTypes: payload.checkTypes,
    };
    const { data }: AxiosResponse<CheckProposalDataResponseSK> =
      await axios.post(url, convertPayloadToSK(req));
    return convertCheckProposal(data);
  },
);

export const getProducts = async (
  queries: GetProductsQueries,
  signal: AbortSignal,
): Promise<Choice[]> => {
  const { data }: AxiosResponse<GetProductsResponse> = await axios.get(
    `${API_URL}DajZoznamProduktov${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
    {
      signal,
    },
  );
  const response = data?.produkt?.map((product: DrugProduct) => ({
    label: `${product.kodProduktPzs} - ${product.nazovProduktPzs}`,
    value: product.kodProduktPzs,
    preferredProducts: product.preferovanyProdukt,
  }));
  store.dispatch(setProducts({ products: data?.produkt || [] }));
  return response || [];
};

export const getDiagnosesList = async (
  queries: GetDiagnosesQueries,
  signal: AbortSignal,
): Promise<Choice[]> => {
  const { data }: AxiosResponse<GetDiagnosesListReponse> = await axios.get(
    `${API_URL}DajZoznamDiagnoz${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
    {
      signal,
    },
  );
  const response = data?.diagnozy?.map((diag: ProposalDiagnose) => ({
    label: `${diag.kod} - ${diag.nazov}`,
    value: diag.kod,
  }));
  return response || [];
};

export const getFormData = createAsyncThunk(
  'drugProposals/getFormData',
  async (queries: GetFormDataQueries) => {
    const url = `${API_URL}DajUdajePreFormularLiekovehoNavrhu${createQueryParams(
      {
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      },
    )}`;
    const { data }: AxiosResponse<GetFormDataResponse> = await axios.get(url);
    return data;
  },
);

export const saveProposal = createAsyncThunk(
  'drugProposals/saveProposal',
  async (payload: SaveProposalPayload) => {
    const url = `${API_URL}UlozLnCnp`;
    const { data }: AxiosResponse<SaveProposalResponse> = await axios.post(
      url,
      payload,
    );
    return data;
  },
);

export const getDrugsDetail = async (
  queries: GetDrugsCnpDetailQueries,
): Promise<GetDrugsDetailResponse> => {
  const { data }: AxiosResponse<GetDrugsDetailResponseSK> = await axios.get(
    `${API_URL}DajDetailLiekovehoNavrhu${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...convertPayloadToSK(queries),
      },
    })}`,
  );
  return convertGetDurgsDetail(data);
};

export const getCnpDetail = async (
  queries: GetDrugsCnpDetailQueries,
): Promise<GetCnpDetailResponse> => {
  const { data }: AxiosResponse<GetCnpDetailResponseSK> = await axios.get(
    `${API_URL}DajDetailLiekovehoNavrhu${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...convertPayloadToSK(queries),
      },
    })}`,
  );
  return convertGetCnpDetail(data);
};

export const getProposalDetailDocuments = async (
  queries: GetDrugsCnpDocumentsQueries,
): Promise<GetProposalDetailDocumentsResponse> => {
  const { data }: AxiosResponse<GetProposalDetailDocumentsResponseSK> =
    await axios.get(
      `${API_URL}DajDokumentyPreDetail${createQueryParams({
        withoutEmpty: false,
        queryParams: {
          ...convertPayloadToSK(queries),
        },
      })}`,
    );
  return convertGetProposalDetailDocuments(data);
};

export const getProposalsList = async (
  queries: GetProposalsListQueries,
): Promise<GetProposalsListResponse> => {
  const { data }: AxiosResponse<GetProposalsListResponseSK> = await axios.get(
    `${API_URL}DajZoznamPodanychNavrhov${createQueryParams({
      withoutEmpty: true,
      queryParams: {
        ...convertPayloadToSK(
          queries.listType !== PROPOSAL_TYPES.CNP
            ? queries
            : {
                ...queries,
                reloadListFromTXS:
                  !store.getState().drugProposals.list.loadedCNP,
              },
        ),
      },
    })}`,
  );
  return convertGetProoposalsList(data);
};

export const saveCompletion = async (
  payload: SaveCompletionRequest,
): Promise<SaveCompletionResponse> => {
  const { data }: AxiosResponse<SaveCompletionResponseSK> = await axios.post(
    `${API_URL}UlozDoplnenie`,
    convertPayloadToSK(payload),
  );
  return convertSaveCompletionResponse(data);
};

export const stornoDrugProposal = async (
  id: number,
): Promise<WriteResponse> => {
  const { data }: AxiosResponse<WriteResponse> = await axios.put(
    `${API_URL}StornujNavrh/${id}`,
  );
  return data;
};

export const stornoCNPProposal = async (id: number): Promise<any> => {
  const { data }: AxiosResponse<any> = await axios.put(
    `${API_URL}StornujPripadCnp/${id}`,
  );
  return data;
};

export const useFetchDrugsDetail = (
  queries: GetDrugsCnpDetailQueries,
  options = {},
) =>
  useQuery({
    queryKey: ['fetchDrugsDetail', queries],
    queryFn: () => getDrugsDetail(queries),
    ...options,
  });

export const useFetchCnpDetail = (
  queries: GetDrugsCnpDetailQueries,
  options = {},
) =>
  useQuery({
    queryKey: ['fetchCnpDetail', queries],
    queryFn: () => getCnpDetail(queries),
    ...options,
  });

export const useFetchProposalDocuments = (
  queries: GetDrugsCnpDocumentsQueries,
  options = {},
) =>
  useQuery({
    queryKey: ['fetchProposalDocuments', queries],
    queryFn: () => getProposalDetailDocuments(queries),
    ...options,
  });

export const useFetchProposalsList = (
  queries: GetProposalsListQueries,
  options = {},
) =>
  useQuery({
    queryKey: ['fetchDrugsProposalsList', queries],
    queryFn: () =>
      getProposalsList(queries).then((data) => {
        if (queries.listType === PROPOSAL_TYPES.CNP)
          store.dispatch(setLoadedCNP({ loaded: true }));
        return data;
      }),
    ...options,
  });

export const useSaveCompletion = () =>
  useMutation({
    mutationFn: (payload: SaveCompletionRequest) => saveCompletion(payload),
  });

// export const useStornoDrugProposal = () =>
//   useMutation({
//     mutationFn: (id: number) => stornoDrugProposal(id),
//   });

// export const useStornoCnpProposal = () =>
//   useMutation({
//     mutationFn: (id: number) => stornoCNPProposal(id),
//   });
