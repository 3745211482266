import { useEffect } from 'react';
import { Button, Container, Icon, TabPanel, Tabs } from '@dovera/design-system';
import useStyles from '../Proposals.styles';
import useStylesApp from '../../../App.styles';
import { navrhyRoutes } from '../../../routes';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { cx } from '../../../utils/exports';
import { reset } from '../../../slices/spaProposals.slice';
import strings from '../../../constants/strings';
import { useQuery } from '../../../hooks/useQuery';
import ProposalDetail from '../ProposalDetail/ProposalDetail';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import {
  resetDrugProposals,
  setLoadedCNP,
} from '../../../slices/drugProposals.slice';
import { PROPOSAL_TYPES } from '../../../types/proposals.types';
import TableKN from './TableKN';
import TableLN from './TableLN';

const texts = strings.proposals.common.submittedProposals;

const tabs: PROPOSAL_TYPES[] = [
  PROPOSAL_TYPES.SPA,
  PROPOSAL_TYPES.Drugs,
  PROPOSAL_TYPES.CNP,
];

const SubmittedProposals = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'KN' });
  const classesApp = useStylesApp();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(reset());
    dispatch(resetDrugProposals());
    dispatch(setLoadedCNP({ loaded: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!query.get('typ'))
      navigate(
        `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${tabs[0]}${query.get('refnum') ? `&refnum=${query.get('refnum')}` : ''}`,
      );
  }, [navigate, query]);

  const renderTabs = query.get('typ') && (
    <Tabs
      activeTabIndex={tabs.findIndex((t) => t === query.get('typ')) || 0}
      disableURLChange
      onChange={(val) =>
        navigate(`${navrhyRoutes.zoznamPodanychNavrhov}?typ=${val.panel.id}`)
      }
    >
      {tabs.map((t) => (
        <TabPanel key={t} id={t} tab={texts.tabs[t]} />
      ))}
    </Tabs>
  );
  const renderHeader = (
    <ContentHeader padBottom={false}>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: 'Návrhy',
              route: '#',
            },
            {
              name: texts.title,
            },
          ]}
        />
      </Container>
      <h2 className="mb">{texts.title}</h2>
      <Button
        onClick={() =>
          navigate(
            `${navrhyRoutes.novyNavrh}?typ=${query.get('typ') === 'KN' ? 'KN' : 'LN'}`,
          )
        }
      >
        <Icon color="white" name="16-plus" />
        <span className="text-space-half-left">{texts.buttonNew}</span>
      </Button>
      {renderTabs}
    </ContentHeader>
  );

  if (query.get('id') && query.get('cisloNavrhu'))
    return (
      <ProposalDetail
        id={Number(query.get('id'))}
        proposalNumber={query.get('cisloNavrhu') || ''}
        type={query.get('typ') || 'KN'}
      />
    );
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      withoutPadding
    >
      <div className={cx(classesApp.pageLayout, classes.section)}>
        {query.get('typ') === PROPOSAL_TYPES.SPA && (
          <TableKN shouldReload={query.get('reload') === '1'} />
        )}
        {[
          PROPOSAL_TYPES.Drugs.toString(),
          PROPOSAL_TYPES.CNP.toString(),
        ].includes(query.get('typ') || '') && (
          <TableLN
            proposalType={query.get('typ') || ''}
            shouldReload={query.get('reload') === '1'}
          />
        )}
      </div>
    </StaticMenuLayout>
  );
};

export default SubmittedProposals;
