import strings from '../../../../../constants/strings';
import { DetailPatient } from '../../../../../types/drugProposals.types';
import { getPatientIcon } from '../../../../../utils/pacienti.utils';
import {
  addressLowerCases,
  formatNameStr,
  formatPhone,
  hotjarMasking,
} from '../../../../../utils/strings.utils';
import Section from './Section';

const texts = strings.proposals.spa.detail;

interface Props {
  patient: DetailPatient;
}

const Patient = ({ patient }: Props) => {
  const { address, dateOfBirth, insureeId, mobile, personName, sex } = patient;
  return (
    <Section
      rows={[
        {
          label: texts.labels.name,
          value: hotjarMasking(formatNameStr(personName)),
        },
        {
          label: texts.labels.insureeId,
          value: hotjarMasking(insureeId),
        },
        {
          label: texts.labels.address,
          value: hotjarMasking(addressLowerCases(address, true)),
        },
        {
          label: texts.labels.mobile,
          value: mobile ? formatPhone(mobile, true) : strings.undefined,
        },
      ]}
      title={texts.subtitles.patient}
      titleIcon={getPatientIcon(sex, dateOfBirth)}
    />
  );
};

export default Patient;
