import { useCallback } from 'react';
import {
  Icon,
  Loader,
  Notification,
  Tag,
  Textarea,
} from '@dovera/design-system';
import strings from '../../../../../../constants/strings';
import useStyles from '../../../../Proposals.styles';
import { cx } from '../../../../../../utils/exports';
import { calculateAge, getMoment } from '../../../../../../utils/date.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { storeStep3 } from '../../../../../../slices/spaProposals.slice';
import { CustomTooltip } from '../../../../../../components/CustomTooltip';
import {
  getEventTypeLabel,
  getTxsControlNtfs,
  spaTxsCheckTypes,
} from '../../../../../../utils/spaProposals.utils';
import {
  MAX_LENGTH_TEXTAREA,
  MIN_LENGTH_EPICRISIS,
} from '../../../../../../constants/proposals';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import { StepButtons } from '../../../common';
import RadioElement from '../../../common/RadioElement/RadioElement';
import DDSDatepicker from '../../../../../../components/DDSDatepicker/DDSDatepicker';

const texts = strings.proposals.spa.new;

interface Props {
  confirmationField: any;
  contraindications?: string[];
  contraindicationsField: any;
  epicrisisField: any;
  eventDateField: any;
  examinations?: string[];
  examinationsField: any;
  onCancel: () => void;
  onContinue: () => void;
  svlzField: any;
}

const StepFields = ({
  confirmationField,
  contraindications,
  contraindicationsField,
  epicrisisField,
  eventDateField,
  examinations,
  examinationsField,
  onCancel,
  onContinue,
  svlzField,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const dispatch = useAppDispatch();
  const {
    step2: { poiData },
    step3,
  } = useSelector((state: RootState) => state.spaProposals.new.stepper);
  const {
    controls,
    formData: { data, error, isLoading },
  } = useSelector((state: RootState) => state.spaProposals.new.data);
  const isLoadingTxsControls: boolean =
    !!(
      controls.isLoadingControl &&
      spaTxsCheckTypes.some((s) => controls.isLoadingControl?.includes(s))
    ) ||
    !!(
      data?.udajParUdalIndSkup?.udalost &&
      eventDateField.input.value &&
      !eventDateField.meta.error &&
      controls.data.length < 3
    );
  const onContinueCallback = useCallback(() => {
    if (step3.showCancelBtnAfter) {
      dispatch(storeStep3({ showCancelBtnAfter: null }));
    } else {
      onContinue();
    }
  }, [dispatch, onContinue, step3.showCancelBtnAfter]);
  const renderDatepicker = data &&
    getEventTypeLabel(data.udajParUdalIndSkup?.udalost) && (
      <div className={cx(classes.dateWrapper, 'mb')}>
        <DDSDatepicker
          {...eventDateField.input}
          error={eventDateField.meta.touched && eventDateField.meta.error}
          id="step3-date"
          isDisabled={isLoadingTxsControls}
          isRequired
          label={getEventTypeLabel(data.udajParUdalIndSkup.udalost)}
          maxDate={new Date()}
          minDate={getMoment().subtract(2, 'years').toDate()}
        />
      </div>
    );

  const renderReason = (
    <div className={classes.longTextarea}>
      <Textarea
        {...epicrisisField.input}
        error={
          epicrisisField.meta.touched &&
          epicrisisField.meta.error && (
            <SafeHtml html={epicrisisField.meta.error} />
          )
        }
        label={
          <RemainingChars
            formLabel={
              <>
                {texts.labels.reason}
                <span className="text-color-error text-space-half-right">
                  {' '}
                  *
                </span>
                <CustomTooltip
                  dialog={
                    data?.udajDetIndSkup?.epikriza || texts.tooltips.epicrisis
                  }
                  id={`epicrisis-tooltip`}
                >
                  <Icon name="16-info" />
                </CustomTooltip>
              </>
            }
            maxLength={MAX_LENGTH_TEXTAREA}
            valueLength={epicrisisField.input.value?.length}
          />
        }
        maxLength={MAX_LENGTH_TEXTAREA}
        minLength={MIN_LENGTH_EPICRISIS}
      />
    </div>
  );
  if (isLoading) return <Loader size={24} />;
  if (!data || error)
    return error ? <Notification message={error} variant="error" /> : <span />;
  const renderTXSControls = getTxsControlNtfs(
    controls.data,
    data?.udajParUdalIndSkup?.perioda || 0,
  ) || <span />;
  const renderFormElements = (
    <>
      {calculateAge(poiData?.dateOfBirth) >= 75 && (
        <RadioElement
          field={confirmationField}
          id="proposals--step3-confirmation"
          label={texts.labels.proposalConfirmation}
          notification={
            (step3.spaConfirmation === 'N' && {
              message: texts.notifications.errors.missingConfirmations,
              type: 'error',
            }) ||
            undefined
          }
          onChange={(value) => dispatch(storeStep3({ spaConfirmation: value }))}
          tooltip={texts.tooltips.moreThan70}
          tooltipClass={classes.radioTooltip}
          type="Áno/Nie"
          value={confirmationField.input.value}
          withNotificationClass={classes.radioWithNotification}
          wrapperClass={classes.radiocheckWrapper}
        />
      )}
      <>
        {(!step3.showCancelBtnAfter ||
          !['spaConfirmation'].includes(step3.showCancelBtnAfter)) &&
          renderDatepicker}
        {isLoadingTxsControls || !data || controls.error ? (
          controls.error ? (
            <Notification message={controls.error} variant="error" />
          ) : (
            <Loader size={24} />
          )
        ) : (
          <>
            {renderTXSControls}
            {(!step3.showCancelBtnAfter ||
              !['spaTXSControls', 'spaConfirmation'].includes(
                step3.showCancelBtnAfter,
              )) &&
              (!getEventTypeLabel(data.udajParUdalIndSkup?.udalost) ||
                eventDateField.input.value) && (
                <RadioElement
                  field={contraindicationsField}
                  help={
                    <ul>
                      {contraindications?.map((c, key) => (
                        <li key={`contraindication--${key}`}>{c}</li>
                      ))}
                    </ul>
                  }
                  id="proposals--step3-contraindications"
                  label={texts.labels.contraindications}
                  notification={
                    (step3.spaContraindications === 'Y' && {
                      message: texts.notifications.errors.hasContraindications,
                      type: 'error',
                    }) ||
                    undefined
                  }
                  onChange={(value) =>
                    dispatch(storeStep3({ spaContraindications: value }))
                  }
                  type="Nemá/Má"
                  value={contraindicationsField.input.value}
                  withNotificationClass={classes.radioWithNotification}
                  wrapperClass={classes.radiocheckWrapper}
                />
              )}
            {(!step3.showCancelBtnAfter ||
              ['spaExaminations', 'spaSVLZ', 'spaTXSControls'].includes(
                step3.showCancelBtnAfter,
              )) &&
              step3.spaContraindications && (
                <RadioElement
                  field={examinationsField}
                  help={
                    <div className={classes.examinationTags}>
                      {examinations?.map((e, key) => (
                        <Tag key={`step3--examination--${key}`}>{e}</Tag>
                      ))}
                    </div>
                  }
                  id={'proposals--step3-examinations'}
                  label={texts.labels.examinations}
                  notification={
                    (step3.spaExaminations === 'N' && {
                      message: texts.notifications.errors.missingExaminations,
                      type: 'error',
                    }) ||
                    (step3.spaExaminations === 'Y' && {
                      message: texts.notifications.info.copyOfExaminations,
                      type: 'info',
                    }) ||
                    undefined
                  }
                  onChange={(value) =>
                    dispatch(storeStep3({ spaExaminations: value }))
                  }
                  type={'Áno/Nie'}
                  value={examinationsField.input.value}
                  withNotificationClass={classes.radioWithNotification}
                  wrapperClass={classes.radiocheckWrapper}
                />
              )}
            {(!step3.showCancelBtnAfter ||
              step3.showCancelBtnAfter === 'spaSVLZ') &&
              step3.spaExaminations && (
                <RadioElement
                  field={svlzField}
                  id={'proposals--step3-svlz'}
                  label={texts.labels.SVLZ}
                  notification={
                    (step3.spaSVLZ === 'N' && {
                      message:
                        texts.notifications.errors.missingYoungerExaminations,
                      type: 'error',
                    }) ||
                    undefined
                  }
                  onChange={(value) => dispatch(storeStep3({ spaSVLZ: value }))}
                  type={'Áno/Nie'}
                  value={svlzField.input.value}
                  withNotificationClass={classes.radioWithNotification}
                  wrapperClass={classes.radiocheckWrapper}
                />
              )}

            {!step3.showCancelBtnAfter && step3.spaSVLZ && renderReason}
          </>
        )}
      </>
    </>
  );
  return (
    <>
      {renderFormElements}
      <StepButtons
        hide={(!step3.showCancelBtnAfter && !step3.spaSVLZ) || isLoading}
        onCancel={onCancel}
        onContinue={onContinueCallback}
        showCancel={!!step3.showCancelBtnAfter}
      />
    </>
  );
};

export default StepFields;
