import { createBrowserHistory as createHistory } from 'history';

export const history = createHistory();

const prefix = `${
  window.env && window.env.config ? window.env.config.baseURI : ''
}`;

const prehladPacientov = `${prefix}/pacienti/prehlad-zdravotnej-starostlivosti`;
const prehladDiagnoz = `${prefix}/prehlad-diagnoz`;
const navRoute = `${prefix}/navrhy`;
export const zuctovanieIndex = `${prefix}/zuctovanie`;
export const cakackyIndex = `${prefix}/cakacie-listiny`;

export const diagnozyRoutes = {
  index: prehladDiagnoz,
  celkoveNakldy: `${prehladDiagnoz}/celkove-naklady`,
  lieky: `${prehladDiagnoz}/lieky`,
  zdravotnickePomocky: `${prehladDiagnoz}/zdravotnicke-pomocky`,
  vykony: `${prehladDiagnoz}/vykony`,
  svalz: `${prehladDiagnoz}/svalz`,
};

export const zmluvyRoutes = {
  hromadnyPodpis: 'hromadny-podpis',
  podpis: 'podpis',
};

export const navrhyRoutes = {
  index: navRoute,
  zoznamPodanychNavrhov: `${navRoute}/zoznam-podanych-navrhov`,
  zoznamSchvalenychNavrhov: `${navRoute}/zoznam-schvalenych-navrhov`,
  novyNavrh: `${navRoute}/novy-navrh`,
  detail: ({
    proposalId,
    proposalNumber,
    type,
  }: {
    proposalId: number;
    proposalNumber: string;
    type: string;
  }) =>
    `${navRoute}/zoznam-podanych-navrhov?cisloNavrhu=${proposalNumber}&id=${proposalId}&typ=${type}`,
};

export const kapRoute = `${prefix}/pacienti/kapitovani-sporni/`;

const routes = {
  autentifikacia: `${prefix}/autentifikacia`,
  cookies: `${prefix}/cookies`,
  dashboard: `${prefix}/`,
  elektronickeSluzby: `${prefix}/elektronicke-sluzby`,
  zdravotnaStarostlivost: `${prefix}/zdravotna-starostlivost`,
  staraEP: `${prefix}/sekcia-v-ep`,
  profil: `${prefix}/profil`,
  kontaktneOsoby: `${prefix}/profil/kontaktne-osoby`,
  bankoveSpojenie: `${prefix}/profil/bankove-spojenie`,
  zmluvnePristroje: `${prefix}/profil/zmluvne-pristroje`,
  overeniaPrehlady: `${prefix}/profil/overenia-prehlady`,
  overeniaPrehladyFilter: `${prefix}/profil/overenia-prehlady/:filter`,
  overeniaPrehladyDetailAmbulancie: `${prefix}/profil/overenia-prehlady/ambulancie/:kodAmbulancie`,
  prihlasovacieUdaje: `${prefix}/profil/zmena-prihlasovacich-udajov`,
  schvaleniePristupu: `${prefix}/profil/schvalenie-pristupu`,
  zmluvyDodatky: `${prefix}/zmluvy-dodatky`,
  detailKampane: `${prefix}/zmluvy-dodatky/:id`,
  detailZmluvyASOC: `${prefix}/zmluvy-dodatky/:id/detail/:idZmluvy`,
  detailDodatkuASOC: `${prefix}/zmluvy-dodatky/:id/detail/:idZmluvy/dodatok/:idDodatku`,
  podpisZmluvyASOC: `${prefix}/zmluvy-dodatky/:id/${zmluvyRoutes.podpis}/:idZmluvy`,
  podpisDodatkuASOC: `${prefix}/zmluvy-dodatky/:id/${zmluvyRoutes.podpis}/:idZmluvy/dodatok/:idDodatku`,
  hromadnyPodpisZmluvASOC: `${prefix}/zmluvy-dodatky/:id/${zmluvyRoutes.hromadnyPodpis}`,
  detailZmluvyPZS: `${prefix}/zmluvy-dodatky/detail/:idZmluvy`,
  otvorDetailZmluvy: (idZmluvy) =>
    `${prefix}/zmluvy-dodatky/detail/${idZmluvy}`,
  podpisZmluvyPZS: `${prefix}/zmluvy-dodatky/${zmluvyRoutes.podpis}/:idZmluvy`,
  podpisDodatkuPZS: `${prefix}/zmluvy-dodatky/${zmluvyRoutes.podpis}/:idZmluvy/dodatok/:idDodatku`,
  detailDodatkuPZS: `${prefix}/zmluvy-dodatky/detail/:idZmluvy/dodatok/:idDodatku`,
  staryDashboard: `${prefix}/stary`,
  poistenci: `${kapRoute}:page`,
  kapitovaniPoistenci: `${prefix}/pacienti/kapitovani-sporni/kapitovani`,
  sporniPoistenci: `${prefix}/pacienti/kapitovani-sporni/sporni`,
  // @ts-ignore
  parameters: window.publiGuid
    ? `${prefix}/prehlad-hodnotiacich-parametrov`
    : `${prefix}/hodnotiace-parametre`,
  hpOld: `${prefix}/hodnotiace-parametre-old`,
  multipleChoiceHp: `${prefix}/hodnotiace-parametre/vyber-parametrov`,
  pdp: `${prefix}/prehlad-diagnostiky-pacientov`,
  pp: `${prehladPacientov}`,
  ppDetail: `${prehladPacientov}/:detailSlug`,
  ppFarmakoterapia: `${prehladPacientov}/farmakoterapia`,
  ppChronicki: `${prehladPacientov}/hypertonici`,
  ppCerpanieZS: `${prehladPacientov}/cerpanie-zdravotnej-starostlivosti`,
  vseobecnyPrehladDiagnoz: prehladDiagnoz,
  diagnozyPrehlad: `${prehladDiagnoz}/:diagnozySlug`,
  categoryDetail: `${prefix}/kvalita`,
  parameter: `${prefix}/hodnotiace-parametre/:skupinaSlug/:parameterSlug`,
  skupina: `${prefix}/hodnotiace-parametre/:skupinaSlug`,
  login: `${prefix}/prihlasenie`,
  callback: `${prefix}/callback`,
  silentrenew: `${prefix}/silent-renew`,
  verzie: '/sw/version',
  dajParameter: (skupina: string, parameter: string) =>
    `${prefix}/hodnotiace-parametre/${skupina}/${parameter}`,
  prehladHPPublic: `${prefix}/prehlad-hodnotiacich-parametrov`,
  publicParam: `${prefix}/prehlad-hodnotiacich-parametrov/:skupinaSlug/:parameterSlug`,
  chybaPrihlasenia: `${prefix}/chyba-prihlasenia`,
  spravy: `${prefix}/spravy`,
  // Zuctovanie
  odoslanieDavok: `${zuctovanieIndex}/odoslanie-davok`,
  prehladChybDavok: `${zuctovanieIndex}/odoslanie-davok/prehlad-chyb`,
  prehladChybOvernychDavok: `${zuctovanieIndex}/prehlad-davok/overene/prehlad-chyb`,
  prehladDavok: `${zuctovanieIndex}/prehlad-davok`,
  odoslaneDavky: `${zuctovanieIndex}/prehlad-davok/odoslane`,
  overeneDavky: `${zuctovanieIndex}/prehlad-davok/overene`,
  faktury: `${zuctovanieIndex}/faktury`,
  prehladDispZaz: `${zuctovanieIndex}/prehlad-dispenzarizovanych-zaznamov`,
  rozdelenieDavok: `${zuctovanieIndex}/rozdelenie-davok`,
  vysledokOdoslaniaDavok: `${zuctovanieIndex}/vysledok-odoslania`,
  // Cakacie listiny
  cakackyOdoslanieDavok: `${cakackyIndex}/odoslanie-davok`,
  cakackyPrehladChybDavok: `${cakackyIndex}/odoslanie-davok/prehlad-chyb`,
  cakackyPrehladDavok: `${cakackyIndex}/prehlad-davok`,
  cakackyVysledokOdoslaniaDavok: `${cakackyIndex}/vysledok-odoslania`,
  // Manazersky prehlad zmluv
  manazerskyPrehladZmluv: `${prefix}/prehlad-cenovych-podmienok`,
  // IDP TEST
  testIDP: `${prefix}/test-idp`,
  // IDP
  logout: `${prefix}/odhlasenie`,
};

export default routes;
