import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

interface StylesProps {
  proposalType: 'KN' | 'LN' | 'CNP' | string;
}

export default createUseStyles({
  headerDescription: {
    marginTop: getRem(16),
    '& button': {
      marginTop: getRem(8),
      '& svg': {
        color: '#FF0000',
        marginRight: `${getRem(8)} !important`,
      },
    },
  },
  section: {
    paddingTop: `${getRem(58)} !important`,
    paddingBottom: `${getRem(58)} !important`,
  },
  sectionStepChanged: {
    marginBottom: getRem(300),
  },
  stepper: ({ proposalType }: StylesProps) => {
    const maxWidth = proposalType === 'LN' ? getRem(536) : getRem(382);
    return {
      '& .form-control:not(.form-control--group)': {
        width: '100%',
        maxWidth,
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '& .form-control.form-control--group': {
        marginBottom: getRem(24),
        '& .form-control': {
          marginBottom: 0,
        },
      },
      '& .choices__list--dropdown': {
        maxWidth: '100%',
        minWidth: '100%',
        [`@media screen and (max-width: ${breakpoints.s}px)`]: {
          minWidth: '100%',
          maxWidth: '100%',
        },
        '& .choices__item': {
          '&[data-value=""]': {
            display: 'none',
          },
        },
        overflow: 'hidden',
        '& .choices__list': {
          maxHeight: getRem(200),
        },
        '& .choices__item--selectable': {
          whiteSpace: 'pre-wrap',
        },
      },
      '& .choices:focus': {
        marginLeft: 2,
      },
      '& .notification': {
        width: proposalType === 'LN' ? '100%' : 'fit-content',
        margin: `${getRem(24)} 0`,
      },
      '& .form-message--help': {
        '& ul': {
          fontSize: getRem(16),
          paddingLeft: getRem(24),
          margin: 0,
        },
      },
      '& textarea': {
        height: getRem(96),
      },
    };
  },
  stepContent: {
    paddingTop: getRem(16),
  },
  autocompleteWrapper: {
    width: '100%',
    maxWidth: getRem(382),
    margin: `0 0 ${getRem(24)}`,
  },
  drugsWrapper: {
    width: '100%',
    maxWidth: getRem(536),
    margin: `0 0 ${getRem(24)}`,
    '& .form-control': {
      maxWidth: '100% !important',
    },
    '& .notification': {
      width: '100%',
    },
    '& > div': {
      position: 'relative',
    },
  },
  autocomplete: {
    width: '100%',
    maxWidth: getRem(382),
    '& input.is-error': {
      border: `1px solid ${color('error')}`,
    },
    '&:not(:last-child)': {
      '& > div': {
        marginBottom: `${getRem(32)} !important`,
      },
    },
    '& ul.autocomplete__menu': {
      width: getRem(600),
      [`@media screen and (max-width: ${breakpoints.s}px)`]: {
        maxWidth: '100%',
      },
    },
    '&.hide-results': {
      '& ul.autocomplete__menu': {
        visibility: 'hidden !important',
      },
    },
    '&.show-results': {
      '& ul.autocomplete__menu': {
        visibility: 'visible',
        display: 'block',
        opacity: 1,
        pointerEvents: 'all',
        overflow: 'auto',
      },
    },
  },
  indicatorGroupWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginLeft: getRem(32),
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: getRem(12),
      },
    },
  },
  radiocheckWrapper: {
    display: 'flex',
    '& .form-control': {
      width: 'fit-content !important',
      marginRight: getRem(48),
    },
  },
  proposalPoints: {
    background: color('grey', 50),
    padding: `${getRem(16)} ${getRem(24)}`,
    marginBottom: getRem(48),
    '& div': {
      display: 'flex',
      '& span': {
        marginLeft: getRem(16),
      },
      '&:not(:last-child)': {
        marginBottom: getRem(16),
      },
    },
  },
  proposalState: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: getRem(22),
      height: getRem(22),
      marginRight: getRem(8),
    },
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > .form-control': {
      width: getRem(211),
      marginRight: getRem(32),
    },
  },
  modal: {
    width: getRem(738),
    '& .form-control--input': {
      width: getRem(190),
    },
  },
  inputWithState: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: getRem(24),
      height: getRem(24),
      marginLeft: getRem(12),
    },
  },
  documentLink: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    '& svg': {
      width: `${getRem(24)} !important`,
      height: `${getRem(24)} !important`,
      marginRight: getRem(12),
    },
  },
  contentSection: {
    padding: `${getRem(16)} 0`,
    '&.text-space-top': {
      marginTop: getRem(26),
    },
    '& span': {
      wordBreak: 'break-word',
    },
  },
  sectionWithBorder: {
    border: `1px solid ${color('grey', 100)}`,
    borderRadius: 8,
    padding: getRem(16),
  },
  sectionWithTitleIcon: {
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        width: getRem(32),
        height: getRem(32),
        marginLeft: getRem(5),
        marginRight: getRem(10),
      },
    },
    '& > div > span, & > div > div': {
      marginLeft: getRem(48),
    },
  },
  sectionTags: {
    '& > span': {
      margin: `0 ${getRem(8)} ${getRem(16)} 0 !important`,
    },
  },
  proposalHeaderRow: {
    display: 'flex',
    '& svg': {
      marginRight: getRem(8),
    },
  },
  proposalDetailButtons: {
    marginLeft: getRem(32),
    '& button': {
      minHeight: 0,
      '&.btn--link': {
        padding: 0,
      },
      '&:not(:first-child)': {
        marginLeft: `${getRem(24)} !important`,
      },
    },
  },
  tags: {
    '& span': {
      margin: `0 ${getRem(16)} ${getRem(12)} 0 !important`,
    },
  },
  modalNotValidConditions: {
    width: getRem(740),
    '& .modal__content': {
      textAlign: 'center',
      '& img': {
        width: getRem(264),
        height: getRem(264),
        marginBottom: getRem(32),
      },
      '& h4': {
        color: color('warning'),
      },
      '& ul': {
        fontSize: getRem(16),
      },
    },
    '& .modal__footer': {
      '& .btn--secondary': {
        color: color('error', 600),
        border: `1px solid ${color('error', 600)}`,
        '&:hover, &:focus, &:active': {
          background: '#ffe9ec',
          textShadow: 'none',
        },
      },
      '& .btn--link': {
        '& svg': {
          marginRight: getRem(8),
        },
      },
      '& .btn-layout': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  result: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: getRem(80),
    '& h4': {
      marginTop: getRem(40),
      '&.success': {
        color: color('primary'),
      },
      '&.warning': {
        color: color('warning'),
      },
      '&.error': {
        color: color('error'),
      },
    },
    '& img': {
      width: getRem(320),
    },
  },
  cancelProposalModal: {
    width: getRem(907),
  },
  dateWrapper: {
    maxWidth: getRem(202),
  },
  examinations: {
    '& .form-control--group': {
      marginBottom: getRem(16),
    },
  },
  examinationTags: {
    '& span': {
      margin: `0 ${getRem(16)} ${getRem(12)} 0 !important`,
    },
  },
  radioWithNotification: {
    '& .form-control--group': {
      marginBottom: getRem(16),
    },
  },
  radioTooltip: {
    position: 'absolute',
    marginLeft: getRem(24),
    '& svg': {
      marginTop: getRem(-5),
      verticalAlign: 'middle',
    },
  },
  step4Form: {
    '& .form-label, & .form-message--help': {
      maxWidth: getRem(540),
      width: '100% !important',
    },
    '& .form-control': {
      maxWidth: '100% !important',
      '& .form-control__input-wrapper, & .input-addons': {
        maxWidth: getRem(382),
        width: '100%',
      },
    },
  },
  statementPoints: {
    paddingLeft: getRem(20),
    marginTop: getRem(8),
    fontSize: getRem(16),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      marginBottom: 0,
    },
  },
  longTextarea: {
    marginBottom: getRem(32),
    '& .form-control, & .input-addons, & .form-control__input-wrapper': {
      maxWidth: `${getRem(500)} !important`,
      '& textarea': {
        minHeight: getRem(140),
      },
    },
    '& .form-label div': {
      '& span:first-child': {
        maxWidth: '77%',
      },
    },
  },
  indicatorListBtn: {
    marginLeft: getRem(24),
    marginTop: getRem(32),
    padding: 0,
    '&.is-absolute': {
      position: 'absolute',
      top: 'calc(50% - 42px)',
      right: getRem(-200),
    },
  },
  ministerException: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > div': {
      maxWidth: getRem(312),
    },
  },
  twoFieldsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: getRem(24),
    '& > div': {
      marginBottom: getRem(24),
      '&:first-child': {
        marginRight: getRem(48),
      },
    },
    '&.no-mrg > div': {
      marginBottom: 0,
    },
  },
  listFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      marginRight: getRem(32),
      minWidth: getRem(196),
      '&:last-child': {
        width: getRem(440),
      },
    },
  },
  filterDatepicker: {
    maxWidth: getRem(211),
  },
  listTable: {
    '& table': {
      '& tr td': {
        '&:not(:last-child)': {
          minWidth: getRem(120),
        },
        '& span': {
          wordBreak: 'normal',
        },
      },
    },
  },
  proposalNtf: {
    '& .notification': {
      '&__iconArea': {
        marginTop: -3,
        '& svg': {
          margin: 0,
        },
      },
      '&__body': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  useCompletion: {
    borderTop: `1px solid ${color('grey', 100)}`,
    padding: `${getRem(24)} 0`,
    '& > div': {
      padding: 0,
    },
  },
  completionForm: {
    maxWidth: getRem(503),
    '& .form-control': {
      marginBottom: getRem(24),
      '& textarea': {
        height: getRem(120),
      },
    },
  },
  completionBtn: {
    display: 'block',
    marginTop: getRem(24),
  },
});
