import { ErrorResponse, LoadingState, Nullable } from '.';
import { Document, DocumentPayload, Dokument } from './models/Dokument';
import {
  AvailableDoctorType,
  CheckType,
  NewProposalStep1,
  PROPOSAL_TYPES,
  ProposalCheck,
  ProposalDiagnose,
  ProposalPOIData,
  ProposalsFilter,
  StepStatus,
} from './proposals.types';

export type DrugProduct = {
  doplnokNazvu: string;
  kodAtcSkupiny: string;
  kodProduktPzs: string;
  nazovProduktPzs: string;
  preferovanyProdukt: {
    kodProduktPzs: string;
    nazovProduktPzs: string;
  };
};

export type IndicationLimatationData = {
  id: number;
  kodObmedzenia: string;
  kodProduktPzs: string;
  nazovObmedzenia: string;
};

type ExpertiseDoctor = {
  code: string;
  expertise: string;
  id: number;
};

export type DrugCnpProposalData = {
  datumPlatnostiOd: string;
  davkovanie: string;
  epikriza: string;
  id: number;
  jeKategorizovany: boolean;
  jednotkaMnozstva: string;
  kodAtcSkupiny: string;
  kodProduktPzs: string;
  kontraIndikacie: string[] | null;
  mnozstvo: number;
  typLiecby: string;
  zdovodnenieLiecby: string[] | null;
};

export interface GetListOfAvailableDoctorsQueries {}

export interface GetListOfAvailableDoctorsResponse {
  zoznamDostupnychLekarov: AvailableDoctorType[];
}

export interface GetProductsQueries {
  hladanyProdukt: string;
  odbornostLekara: string;
  odbornostNz: string;
}

export interface GetDiagnosesQueries {
  hladanaDiagnoza: string;
}

export interface GetProductsResponse {
  produkt: DrugProduct[];
}

export interface CheckProposalDataPayload {
  checkTypes: CheckType[];
  dateValidityFrom?: string;
  diagnosisCode?: Nullable<string>;
  formId?: string;
  insureeId?: string;
  isCNP?: boolean;
  productCode?: Nullable<string>;
  proposingDoctor?: ExpertiseDoctor;
  proposingExpertise?: ExpertiseDoctor;
  quantity?: number;
}

export interface CheckProposalDataResponse {
  data: {
    additionalInfo: {
      value1: string;
      value2: string;
      value3: string;
      value4: string;
      value5: string;
      value6: string;
    } | null;
    checkResult: 'OK' | 'NOK';
    checkType: CheckType;
    errorCode: string;
    errorDescription: string;
    errorDetail: string;
    errorWeightCode: string;
  }[];
  formId: string;
}
export interface GetFormDataQueries {
  formularId: string;
  kodDiagnoza: string;
  kodProdukt: string;
  maMV: boolean;
  odbornostLek: string;
  odbornostNz: string;
  rodneCislo: string;
}

export interface GetFormDataResponse {
  cisloPredchadzajucehoLnCnp: string;
  udajIndikacnehoObmedzenia: IndicationLimatationData[];
  udajParLiekNavrhuCnp: DrugCnpProposalData;
}

export interface SaveProposalPayload {
  datumPlatnostiOd: string;
  davkovanie: string;
  epikriza: string;
  formularId: string;
  jednotkaMnozstva: string | null;
  kodATCSkupiny: string | null;
  kodDiagnoza: string;
  kodIO: string | null;
  kodProduktPZS: string;
  maKontraindikacie: boolean | null;
  maMV: boolean;
  mnozstvo: number;
  navrhujucaNZ: {
    id: number;
    kod: string;
    odbornost: string;
  };
  navrhujuciLekar: {
    id: number;
    kod: string;
    odbornost: string;
  };
  popisSposobuDavkovania: string;
  prilohy?: DocumentPayload[] | null;
  rodneCislo: string;
  zdovodnenieLiecby: string;
}

export interface SaveProposalResponse {
  chyba: string;
  evidencneCislo: string;
  jeCnp: boolean;
}

export type ProposalHeader = {
  accepted: string;
  exposedDate: string;
  exposedType: string;
  processingDate: string;
  state: string;
  validityFrom: string;
  validityTo: string;
};

export interface DrugProposalsState {
  detail: {
    completions: {
      [key: string]: {
        attachments?: Dokument[];
        dateTime: string;
        text: string;
      };
    };
    header: ProposalHeader | null;
    isLoading: boolean;
  };
  list: {
    loadedCNP: boolean;
  };
  new: {
    data: {
      controls: {
        data: ProposalCheck[];
        error: string;
        errorInsuree: string;
        isLoadedTXSControls: boolean;
        isLoadingControl: CheckType[] | null;
      };
      doctors: {
        data: AvailableDoctorType[] | null;
        error: string | null;
        isLoading: boolean;
      };
      formData: {
        data: GetFormDataResponse | null;
        dataState: LoadingState;
        error: string | null;
        formId: string;
      };
      products: DrugProduct[];
    };
    isNotValidModalOpened: boolean;
    isReseted: boolean;
    save: {
      data: SaveProposalResponse | null;
      dataState: LoadingState;
      error: string | null;
    };
    stepper: {
      activeStep: number;
      step1: NewProposalStep1;
      step2: {
        insureeId: string;
        poiData: ProposalPOIData | null;
        stepStatus: StepStatus;
      };
      step3: {
        dateFrom: string;
        diagnose: ProposalDiagnose | null;
        dosage: string | null;
        dosageDescription?: string;
        epicrisis?: string;
        hasContraindication: Nullable<string>;
        hasIndication: Nullable<string>;
        hasMV: Nullable<string>;
        indicatorLimitation?: Nullable<{ code: string; label: string }>;
        product: Nullable<{ atc?: string | null; code: string; label: string }>;
        quantity: Nullable<number | string>;
        reason?: string;
        stepStatus: StepStatus;
      };
    };
  };
}

export enum DrugProposalField {
  Contraindication = 'Contraindication',
  DateFrom = 'DateFrom',
  Diagnose = 'Diagnose',
  Epicrisis = 'Epicrisis',
  IndicatorLimitationCode = 'IndicatorLimitationCode',
  MinisterException = 'MinisterException',
  Product = 'Product',
  QuantityDosage = 'QuantityDosage',
  Reason = 'Reason',
}

export type DrugProposalSteps = 0 | 1 | 2;

export enum FieldElementIdEnum {
  Contraindication = 'drugproposals--step3-contraindications',
  DateFrom = 'drugproposals--step3-dateFrom',
  Diagnose = 'proposals--diagnose',
  Dosage = 'drugproposals--step3-dosage',
  DosageDescription = 'drugproposals--step3-dosageDescription',
  Epicrisis = 'drugproposals--step3-epicrisis',
  IndicativeLimit = 'drugproposals--step3-indicativeLimit',
  MinisterException = 'drugproposals--step3-permission',
  Product = 'proposals--product-code',
  Quantity = 'drugproposals--step3-quantity',
  Reason = 'drugproposals--step3-reason',
  UploadAttachments = 'drugproposals--step3-upload-attachments',
}

export type FieldElementId =
  | FieldElementIdEnum.Contraindication
  | FieldElementIdEnum.Diagnose
  | FieldElementIdEnum.Product;

export type DetailPatient = {
  address: string;
  dateOfBirth: string;
  insureeId: string;
  insureeIdTxs: number;
  mobile: string | null;
  personName: string;
  sex: 'M' | 'Z' | 'X' | null;
};

export type DetailDoctor = {
  ambulance: string | null;
  doctor: string;
  email: string | null;
  hospital: string | null;
};

export type DetailProposalInformation = {
  codeIL: string | null;
  diagnosisCode: string;
  diagnosisName: string;
  dosage: string | null;
  epicrisis: string | null;
  hasContraintication: boolean;
  hasME: boolean;
  nameIL: string | null;
  productCode: string;
  productName: string;
  quantity: number;
  treatmentJustification: string | null;
};

export type DetailCnpInformation = {
  codeIL: string | null;
  diagnosisCode: string;
  diagnosisName: string;
  dosage: string | null;
  epicrisis: string | null;
  hasContraintication: boolean;
  hasME: boolean;
  nameIL: string | null;
  productCode: string;
  productName: string;
  quantityApproved: number;
  quantityConsumed: number;
  quantityDelivered: number;
  quantityOrdered: number;
  quantityRequired: number;
  quantityTransfered: number;
  treatmentJustification: string | null;
};

export type DetailProposalData = {
  accepted: string;
  completionTxs: string;
  decided: string;
  exposeType: string;
  exposed: string;
  insuranceStatement: string | null;
  proposalId: number;
  proposalNumber: string;
  proposalValidityFrom: string;
  proposalValidityTo: string;
  state: string;
};

export interface GetDrugsCnpDetailQueries {
  detailType: 'LN' | 'CNP' | PROPOSAL_TYPES;
  proposalId: number;
  proposalNumber: string;
}

export interface GetDrugsDetailResponse {
  detail: DetailProposalData;
  doctor: DetailDoctor;
  information: DetailProposalInformation;
  patient: DetailPatient;
}

export interface GetCnpDetailResponse {
  detail: DetailProposalData;
  doctor: DetailDoctor;
  information: DetailCnpInformation;
  patient: DetailPatient;
}

export interface GetDrugsCnpDocumentsQueries {
  proposalId: number;
  proposalNumber: string;
}

export interface GetProposalDetailDocumentsResponse {
  documents: Document[];
}

export interface GetProposalsListQueries {
  filter?: ProposalsFilter;
  limit: number;
  listType: PROPOSAL_TYPES | string;
  offset: number;
  reloadListFromTXS: boolean;
}

export interface GetProposalsListResponse {
  count: number;
  proposals: {
    doctor: {
      ambulanceCode: string;
      ambulanceName: string;
      code: string;
      personName: string;
      personSurname: string;
      titleAfter: string;
      titleBefore: string;
    };
    id: number;
    insuree: {
      insureeId: string;
      personName: string;
      personSurname: string;
    };
    productCode: string;
    productName: string;
    proposalId: number;
    proposalNumber: string;
    proposalType: string;
    proposalValidityFrom: string;
    proposalValidityTo: string;
    state: string;
  }[];
}

export interface SaveCompletionRequest {
  attachments?: DocumentPayload[];
  completionPzs: string;
  insureeIdTxs: number;
  proposalId: number;
  proposalNumber: string;
  type: string;
}

export interface SaveCompletionResponse {
  error: ErrorResponse;
  result: boolean;
}
export type ToastActionType = {
  isVisible: boolean;
  onHide?: () => void;
  result: 'success' | 'error' | null;
  text: string;
};

export type ResultType = 'success' | 'error' | null;
